const en = {
    "header": "At Creel/abogados we have specialized Practice Areas to provide personalized solutions according to each of our clients' needs and interests.",
    "title": "Discover in detail our Practice Areas.",
    "subtitle": "and meet the Team of specialists in each area.",
    "accordion": [
        {
            "title": "Corporate and Commercial Law", 
            "p": [
                {
                    title: "",
                    items: ["Our Corporate Team provides comprehensive advice on all aspects relating to commercial and business activities of our clients, including Corporate Law, Commercial Contracts, Mergers and Acquisitions, Private Equity and Corporate Governance."]
                },
                {
                    title: "<h3 style='font-family: Poppins Light' class='text-secondary text-[20px] mb-[8px] mt-[20px]'>Corporate Law</h3>",
                    items: [`<p>We advise clients in connection with their day-to-day corporate needs, as well as with sophisticated and complex transactions. From the incorporation, maintenance, transformation, and dissolution and liquidation of all types of commercial and civil entities, to the creation of corporate groups, conglomerates and consortiums, as well as corporate restructurings.</p>`]
                },
                {
                    title: "<h3 style='font-family: Poppins Light' class='text-secondary text-[20px] mb-[8px] mt-[18px]'>Commercial Contracts</h3>",
                    items: ["We specialize in the drafting and negotiating a wide range commercial agreements and negotiable instruments to properly safeguard the commercial transactions of our clients."],
                },
                {
                    title: "<h3 style='font-family: Poppins Light' class='text-secondary text-[20px] mb-[8px] mt-[18px]'>Mergers and Acquisitions</h3>",
                    items: ["We represent buyers and sellers in all kinds of mergers and acquisitions, including transactions involving tangible and intangible assets, leveraged acquisitions, public and private bidding processes, as well as joint ventures and strategic alliances.", "Our services encompass legal due diligence drafting and negotiating letters of intent, memorandums of understanding, purchase and sale agreements, shareholders agreements, merger agreements, and all the corporate documentation involved in corporate restructurings. Likewise, we advise our clients in securing all types of permits and governmental authorizations required to perfect the mergers and acquisitions transactions in which we participate."]
                },
                {
                    title: "<h3 style='font-family: Poppins Light' class='text-secondary text-[20px] mb-[8px] mt-[18px]'>Private Equity</h3>",
                    items: ["We represent private equity and debt funds in a wide array of matters and transactions, such as fund formation, structuring of investment and management vehicles, investments and acquisitions of minority and controlling positions, financing structures, as well as liquidity events and market exits."],
                },
                {
                    title: "<h3 style='font-family: Poppins Light' class='text-secondary text-[20px] mb-[8px] mt-[18px]'>Corporate Governance</h3>",
                    items: ["We offer comprehensive advice in every aspect relative to the corporate governance of companies, whether private or listed on the Mexican Stock Exchanges, including the design and structuring of shareholder control mechanisms, shared management structures, implementation of management controls in bylaws or shareholders agreements, as well as on the design and operation of management bodies and committees."],
                }
            ],
            "images": {
                "title": "Area Contacts",
                 items: [
                    {
                        image: require('../../assets/equipo/Socios/Home/CarlosCreel_Esc.jpg'),
                        name: "Carlos Creel C.",
                        link: "/partners/1",
                    },
                    {
                        image: require('../../assets/equipo/Socios/Home/GustavoStruck_Esc.jpg'),
                        name: "Gustavo Struck Creel",
                        link: "/partners/3",
                    },
                    {
                        image: require('../../assets/equipo/Socios/Home/CesarMorales_Esc.jpg'),
                        name: "César Morales G.",
                        link: "/partners/5",
                    },
                    {
                        image: require('../../assets/equipo/Socios/Home/PabloArellano_Esc.jpg'),
                        name: "Pablo Arellano Q.",
                        link: "/partners/7",
                    },
                    {
                        image: require('../../assets/equipo/Socios/Home/Guillermo_Home.jpg'),
                        name: "Guillermo Rocha L.",
                        link: "/partners/9",
                    }
                ]
            },
            "banner": [require('../../assets/practica/02_CorporativoMercantil/AP-DCorporativo_escritorio.jpg')],
            "measures": {
                width: 447,
                height: 327
            }
        },
        {
            "title": "Finance",
            "p": [
                {   
                    title: "",
                    items: ["Our Finance practice covers transactional and regulatory matters. We provide advice in banking and securities transactions, representing creditors and debtors, as well as issuers and underwriters. Likewise, we represent regulated financial entities in their day-to-day operations and regulatory compliance, as well as foreign institutions on their cross-border financial activities.", "<p class='mt-[40px] mb-[70px]'>In the Fin-tech sector we assist our clients in establishing their presence in Mexico and the structuring of financing platforms, and facilitating the provision and commercialization of products.</p>"]
                },
                {
                    title: "<h3 style='font-family: Poppins Light' class='text-secondary text-[20px] mb-[8px] mt-[40px]'>Banking</h3>",
                    items: ["We represent companies, funds and financial institutions, banks and non-bank banks in the structuring and negotiation of all types of financings, including single and syndicated loans, secured and unsecured, acquisition and structured finance, bridge and mezzanine loans, as well as in the related security packages.", "Our services include providing advice on debt refinancing and restructurings."]
                },
                {
                    title: "<h3 style='font-family: Poppins Light' class='text-secondary text-[20px] mb-[15px] mt-[30px]'>Securities and Capital Markets</h3>",
                    items: ["We advise issuers and underwriters in capital markets transactions, including public offerings conducted by REITs (FIBRAs), development capital certificates (CKDs), investment projects certificates (CERPIs) and securitizations, among others. Our Team regularly advises companies listed in the Mexican Stock Exchanges, their shareholders, Board and committees’ members and relevant officers on regulatory compliance and corporate governance matters."]
                }
            ],
            images: {
                "title": "Area Contacts",
                items: [
                    {
                        image: require('../../assets/equipo/Socios/Home/CarlosCreel_Esc.jpg'),
                        name: "Carlos Creel C.",
                        link: "/partners/1",
                    },
                    {
                        image: require('../../assets/equipo/Socios/Home/GustavoStruck_Esc.jpg'),
                        name: "Gustavo Struck Creel",
                        link: "/partners/3",
                    },
                    {
                        image: require('../../assets/equipo/Socios/Home/CesarMorales_Esc.jpg'),
                        name: "César Morales G.",
                        link: "/partners/5",

                    },
                    {
                        image: require('../../assets/equipo/Socios/Home/PabloArellano_Esc.jpg'),
                        name: "Pablo Arellano Q.",
                        link: "/partners/7",
                    },
                    {
                        image: require('../../assets/equipo/Socios/Home/Guillermo_Home.jpg'),
                        name: "Guillermo Rocha L.",
                        link: "/partners/9",
                    }
                ]
            },
            "banner": [require('../../assets/practica/03_Financiero/AP-DFinanciero_escritorio.jpg')],
            "measures": {
                width: 447,
                height: 327
            }
        },
        {
            "title": "Tax",
            "p": [
                {
                    title: "",
                    items: ["Our Tax Team provides specialized advice on tax matters related to the structuring, negotiation and implementation of transactions considering our clients’ specific needs and the industries or sectors in which they participate."]
                },
                {
                    title: "<h3 style='font-family: Poppins Light' class='text-secondary text-[20px] mb-[8px] mt-[47px]'> Tax Litigation </h3>",
                    items: ["We represent clients in administrative and judicial actions before tax authorities at local and federal levels, including the negotiation of agreements with tax authorities, as well as appeals and constitutionality review procedures."]
                },
                {
                    title: "<h3 style='font-family: Poppins Light' class='text-secondary text-[20px] mb-[8px] mt-[18px]'>Transfer Pricing</h3>",
                    items: ["Our Tax Team provides advice on the reviewing transfer pricing policies of our clients, continuously training and staying updated with relevant developments and guidelines of the OECD and Mexican tax authorities."]
                },
                {
                    title: "<h3 style='font-family: Poppins Light' class='text-secondary text-[20px] mb-[8px] mt-[25px]'>Foreign Trade</h3>",
                    items: ["We assist clients with foreign trade matters, offering specialized advice and representing them before customs and trade authorities, particularly in matters involving trade remedy practices (antidumping and countervailing proceedings), tariff classification and country of origin determination for products imported from countries with which Mexico has Trade Agreements."]
                }
            ],
            images: {
                title: "Area Contacts",
                items: [
                    {
                        image: require('../../assets/equipo/Socios/Home/JavierPortas_Esc.jpg'),
                        name: "Javier Portas L.",
                        link: "/partners/2",
                    }
                ]
            },
            "banner": [require('../../assets/practica/03_Fiscal/AP-DFiscal_escritorio.jpg')],
            "measures": {
                width: 451,
                height: 263
            }
        }, 
        {
            "title": "Labor and Employment",
            "p": [
                {
                    title: "",
                    items: ["Our Labor and Employment Law services are focused on developing a healthy and stable work environment in which conflicts are prevented or reduced, following the highest ethical and professional standards.", "<p class='mt-[40px]'>We counsel our clients on the hiring and termination of labor relationships, including unionized personnel and top executives, as well as the revisions of general labor conditions. Likewise, we prepare offer letters, confidentiality agreements, internal work regulations, and plans and protocols for the prevention and eradication of labor discrimination and violence. We assist our clients in the execution, management and termination of collective labor agreements with local and national unions, in accordance with the existing free union association and collective negotiation rules. Also, we participate in the design and structuring of social security plans, incentives, bonus, stock options, among others.</p>", "<p class='mt-[60px] mb-[40px]'>Our Labor Law practice involves the representation of our clients in individual and collective litigation before the local and federal Labor Boards and Courts.<p>", "Our Labor Team constantly collaborates with our M&A Team in legal due diligence processes and the review of agreements to identify contingencies that may impact the transactions, as well as in the implementation of personnel transfers and revision of labor conditions."]
                }
            ],
            images: {
                title: "Area Contacts",
                items: [
                    {
                        name: "Alejandro Gorozpe Z.",
                        link: "/advisers/4",
                        image: require('../../assets/equipo/Consejeros/Home/AlejandroGorozpe_Home.jpg')
                    },
                ]
            },
            "banner": [require('../../assets/practica/04_Laboral/AP-DLaboral_escritorio.jpg')],
            "measures": {
                width: 453,
                height: 443
            }
        },
        {
            "title": "Real Estate",
             "p": [
                {
                    title: "",
                    items: ["We provide expert advice on a wide variety of real estate transactions, including the residential, industrial, commercial and tourist sectors, covering the purchase, sale and lease of real estate assets, as well as designing and structuring condominium property and time-sharing regimes. Our services extend to the review and analysis of all legal issues relevant to the development, construction, management and operation of real estate projects, as well as the obtainment of permits and authorizations required for such projects.", "<p class='mt-[20px]'>Furthermore, we represent investors in the creation of private and public real estate funds in Mexico, and assist local and foreign clients in joint ventures relating to the real estate market.</p>"]
                }
             ],
             images: {
                title: "Area Contacts",
                items: [
                    {
                        image: require('../../assets/equipo/Consejeros/Home/JorgeTorresB_Esc.jpg'),
                        name: "Jorge Torres B.",
                        link: "/advisers/2",
                    },
                    {
                        image: require('../../assets/equipo/Socios/Home/GustavoStruck_Esc.jpg'),
                        name: "Gustavo Struck Creel",
                        link: "/partners/3",
                    },
                    {
                        image: require('../../assets/equipo/Consejeros/Home/Alejandro_Esc.jpg'),
                        name: "Alejandro Creel Y.",
                        link: "/advisers/3",
                    },
                ]
             },
             "banner": [require('../../assets/practica/05_Inmobiliario/AP-DInmobiliario_escritorio.jpg')],
             "measures": {
                width: 491,
                height: 375
            }
        },
        {
            "title": "Antitrust and Competition",
            "p": [
                {
                    title: "",
                    items: ["We provide comprehensive advice on all matters and transactions related to the Federal Economic Competition Law, including concentration notices, investigations of monopolistic practices, both relative or absolute, administrative procedures. Additionally, we assist clients in matters related to exclusivity and non-compete agreements."]
                }
            ],
            images: {
                title: "Area Contacts",
                items: [
                    {
                        "name": "Fernanda Melgar G.",
                        "link": "/associates/8",
                        "image": require('../../assets/equipo/Asociados/Home/MariaFernandaMelgar_Esc.jpg')
                    },
                ]
            },
            "banner": [require('../../assets/practica/06_CompetenciaEconomica/AP-Competencia_escritorio.jpg')],
            "measures": {
                width: 491,
                height: 402
            }

        },
        {
            "title": "Natural Resources",
            "p": [
                {
                    title: "",
                    items: ["Our Natural Resources Team advises local and international groups in projects involving the use and exploitation of renewable and non-renewable natural resources, including Energy and Mining."]
                },
                {
                    title: "<h3 style='font-family: Poppins Light' class='text-secondary text-[20px] mb-[15px] mt-[32px]'>Energy and Infrastructure</h3>",
                    items: ["Our Energy Team represents a wide variety of investors, funds, creditors, developers and operators in all the phases of energy projects, including the electricity, oil, petrochemical and gas sectors, as well as in a wide variety of infrastructure projects."]
                },
                {
                    title: "<h3 style='font-family: Poppins Light' class='text-secondary text-[20px] mb-[8px] mt-[32px] pt-[70px]'>Mining Law</h3>",
                    items: ["With a tradition of several decades dating back to the origins of our Firm and which practice area was the main specialty of our founder, Luis J. Creel Luján, we continue providing advice on Mining Law to domestic and foreign clients. Our services include the acquisition, maintenance and renewal of mining concessions and permits, the design and structuring of supply agreements, and the acquisition and funding of mining projects."]
                }
            ],
            images: {
                title: "Area Contacts",
                items: [
                    {
                        image: require('../../assets/equipo/Socios/Home/GustavoStruck_Esc.jpg'),
                        name: "Gustavo Struck Creel",
                        link: "/partners/3",
                    },
                    {
                        image: require('../../assets/equipo/Consejeros/Home/Alejandro_Esc.jpg'),
                        name: "Alejandro Creel Y.",
                        link: "/advisers/3"
                    },
                ]
            },
            "banner": [require('../../assets/practica/07_RecursosNaturales/AP-Recursos_escritorio.jpg')],
            "measures": {
                width: 451,
                height: 331
            }
        },
        {
            "title": "Litigation and Dispute Resolution",
            "p": [
                {
                    title: "",
                    items: ["Our Litigation Team has extensive experience advising and representing local and foreign companies in all types of complex litigation cases. We handle a broad range of disputes, including constitutional, administrative, civil, and commercial litigation before local and federal courts.", "<p class='mt-[30px]'>In addition, our practice includes the representation of clients in alternative dispute resolution (ADR) mechanisms, such as mediation and arbitration, participating in <span style='font-family: Poppins Italic'>ad hoc</span> procedures and before the main domestic and international institutions.</p>"]
                }
            ],
            images: {
                title: "Area Contacts",
                items: [
                    {
                        image: require('../../assets/equipo/Socios/Home/ErnestoSaldate_Esc.jpg'),
                        name: "Ernesto Saldate A.",
                        link: "/partners/8",
                    },
                ]
            },
            "banner": [require('../../assets/practica/08_LitigoSolucion/AP-Litigio_escritorio.jpg')],
            "measures": {
                width: 491,
                height: 488
            }
        },
        {
            "title": "ESG",
            "p": [
                {
                    title: "",
                    items: ["<p style='height: 260px'>We have assembled a specialized Team of with vast experience in providing comprehensive legal advice in ESG (Environment, Society and Corporate Governance). Our ESG Team comprises lawyers from several of our Areas of Practice, including Corporate, Labor and Real Estate, which allows us to cover all ESG compliance requirements and best practices; understanding the specific needs of our clients’ businesses and industrial sectors.</p>"]
                }
            ],
            images: {
                title: "Area Contacts",
                items: [
                    {
                        image: require('../../assets/equipo/Socios/Home/GustavoStruck_Esc.jpg'),
                        name: "Gustavo Struck Creel",
                        link: "/partners/3",
                    },
                    {
                        image: require('../../assets/equipo/Socios/Home/PabloArellano_Esc.jpg'),
                        name: "Pablo Arellano Q.",
                        link: "/partners/7",
                    },
                    {
                        image: require('../../assets/equipo/Consejeros/Home/Alejandro_Esc.jpg'),
                        name: "Alejandro Creel Y.",
                        link: "/advisers/3"
                    }
                ]
            },
            "banner": [require('../../assets/practica/09_ASG/AP-ASG_escritorio.jpg')],
            "measures": {
                width: 491,
                height: 226
            }
        },
        {
            title: "Compliance",
            "p": [
                {
                    title: "",
                    items: ["Working closely with our Corporate and Antitrust Teams, we assist clients in designing and implementing preventive, management and remediation programs related to regulatory compliance on Data Protection, Anti-money laundering, Economic Competition and Anti-corruption, in the preparing codes of ethics and manuals, as well as in governmental audits and related investigations.", "<p class='mt-[50px]'>In Anti-corruption matters, we also provide advice to foreign corporations operating in Mexico on compliance with international anti-corruption regulations, such as the US Foreign Corrupt Practices Act (FCPA) and the UK Bribery Act.</p>"]
                },
            ],
            images: {
                title: "Area Contacts",
                items: [
                    {
                        image: require('../../assets/equipo/Socios/Home/PabloArellano_Esc.jpg'),
                        name: "Pablo Arellano Q.",
                        link: "/partners/7",
                    },
                    {
                        image: require('../../assets/equipo/Socios/Home/Guillermo_Home.jpg'),
                        name: "Guillermo Rocha L.",
                        link: "/partners/9",
                    },
                    {
                        image: require('../../assets/equipo/Socios/Home/ErnestoSaldate_Esc.jpg'),
                        name: "Ernesto Saldate A.",
                        link: "/partners/8"
                    },
                ]
            },
            "banner": [require('../../assets/practica/010_Cumplimiento/AP-Cumplimiento_escritorio.jpg')],
            "measures": {
                width: 475,
                height: 300
            }

        }
    ]
}

export default en;