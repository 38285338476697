const es = {
    "/partners/1": {
        "image": require('../../assets/equipo/Socios/Biografia/CarlosCreel_Esc.jpg'),
        "image_team": require('../../assets/equipo/Socios/Home/CarlosCreel_Esc.jpg'),
        "vcard": require('../../assets/vcards/Carlos_Creel C..vcf'),
        "link": "/partners/1",
        "name": "Carlos Creel C.",
        "subtitle": "Socio Senior",
        "description": [{ name: "Derecho Corporativo y Mercantil", link: "/practice-areas#corporate-and-commercial-law" }, { name: "Fusiones y Adquisiciones", link: "" }, { name: "Derecho Financiero", link: "/practice-areas#finance" }],
        "contact": {
            "title": "Datos de contacto",
            "location": "Ciudad de México",
            "phone": "+52 (55) 1167-3010",
            "email": "carlos.creel@creelabogados.com"
        },
        "p": ["Carlos es el Socio Senior del Despacho. Centra su práctica profesional en las áreas de Derecho Corporativo, Derecho Bancario y Derecho Bursátil. Su práctica incluye asesorar a empresas públicas y privadas en operaciones de Fusiones y Adquisiciones, al igual que en asuntos de gobierno corporativo, co-inversiones (<span style='font-family: Poppins Italic'>joint ventures</span>) y alianzas estratégicas, así como a fondos de capital privado en su formación, y en sus operaciones de inversión y desinversión. Asimismo, cuenta con amplia experiencia en operaciones de financiamiento corporativo, incluyendo financiamiento garantizado y préstamos sindicados, así como en operaciones en los mercados de capitales y de deuda, representando a emisoras y bancos de inversión en ofertas públicas iniciales, ofertas secundarias y ofertas globales.", "Carlos representa a oficinas de familia (<span style='font-family: Poppins Italic'>family offices</span>) y a empresas propiedad de familias o controladas por estas en consultas especializadas y proyectos estratégicos, incluyendo la planeación sucesoria y transición generacional. De manera regular y activa, Carlos asesora a instituciones filantrópicas en su gobierno corporativo y operaciones relacionadas. Es miembro del Consejo y de los Comités Ejecutivos de Fundación Empresarios por la Educación Básica (ExEb) y Alianza Médica para la Salud (AMSA)."],
        "qualifications": {
            "title": "Trayectoria",
            "items": [
                {
                    "title": "Educación y Experiencia Profesional",
                    "p": ["Abogado - Escuela Libre de Derecho (1984)", "Asociado Extranjero - Shearman & Sterling, Nueva York (1984-1985)", "Programa de Alta Dirección AD-2 – IPADE (2018)"]
                },
                {
                    "title": "Experiencia Académica",
                    "p": ["Carlos ha sido Profesor de Derecho Corporativo en el Instituto Tecnológico Autónomo de México (ITAM), Profesor Adjunto de Derecho Internacional Privado en la Escuela Libre de Derecho y desde el 2011 es Profesor de Gobierno Corporativo y Capital Privado en el Programa de Maestría en Derecho de la Empresa de la Universidad Panamericana."]
                },
                {
                    "title": "Publicaciones",
                    "p": ["El Lic. Creel ha publicado artículos y ensayos en publicaciones especializadas, sobre una amplia variedad de áreas de Derecho Mexicano, incluyendo gobierno corporativo y capital privado:",
                        "• Capítulo “México” en “Corporate Governance 2022”, editado por Adam O. Emmerich y Sebastian V. Niles, Wachtell, Lipton, Rosen & Katz, publicado por ICLG, 2022.",
                        "• Capítulo “México” en “Corporate Governance 2020”, editado por Adam O. Emmerich y Sebastian V. Niles, Wachtell, Lipton, Rosen & Katz, publicado por ICLG, 2020.",
                        "• Capítulo “México” en “NYSE: Corporate Governance Guide”, publicado en asociación con la Bolsa de Valores de Nueva York, editado por Steven A. Rosenblum, Karessa L. Cain y Sebastian V. Niles, Wachtell, Lipton, Rosen & Katz, 2015.",
                        "• Capítulo “México” en “Corporate Laws of the World” – “Gesellschaftsrecht des Auslands”, editado por Gerhard Wegen, Andreas Spahlinger y Marcel Barth, Gleiss Lutz, publicado por Verlag C.H.BECK, 2013.",
                        "• “La Sociedad Anónima Promotora de Inversión, publicado en la Obra Jurídica Enciclopédica en Homenaje a la Escuela Libre de Derecho en su Primer Centenario, Derecho Mercantil, por Editorial Porrúa, México, 2012.",
                        "• “Duties and Liabilities of Directors of Mexican Stock Corporations”, publicado en The International Who’s Who of Corporate Governance Lawyers, 2010."
                    ]
                }
            ]
        },
        "affiliations": {
            "title": "Asociaciones",
            "p": ["• Barra Mexicana, Colegio de Abogados", "• Ilustre y Nacional Colegio de Abogados"]
        },
        "languages": {
            "title": "Idiomas",
            "p": ["• Español", "• Inglés"]
        }
    },
    "/partners/2": {
        "name": "Javier Portas L.",
        "link": "/partners/2",
        "image": require('../../assets/equipo/Socios/Biografia/JavierPortas_Esc.jpg'),
        "image_team": require('../../assets/equipo/Socios/Home/JavierPortas_Esc.jpg'),
        "vcard": require('../../assets/vcards/Javier_Portas L..vcf'),
        "subtitle": "Socio",
        "description": [{ name: "Derecho Fiscal", link: "/practice-areas#tax" }, { name: "Cumplimiento Regulatorio", link: "/practice-areas#compliance" }],
        "contact": {
            "title": "Datos de contacto",
            "location": "Ciudad de México",
            "phone": "+52 (55) 1167-3037",
            "email": "javier.portas@creelabogados.com"
        },
        "p": ["Javier es Socio del Despacho. Su práctica se enfoca en tributación corporativa, mexicana e internacional. Brinda asesoría fiscal corporativa a sociedades nacionales y extranjeras, incluyendo todo tipo de entidades mercantiles y civiles, empresas de co-inversión (<span style='font-family: Poppins Italic''>joint ventures</span>), así como fideicomisos y fondos de inversión. La práctica de Javier también incluye la atención de litigios fiscales en las cortes federales y locales.", "Su práctica asimismo se enfoca en asuntos de comercio internacional y la aplicación de los diversos Tratados de Libre Comercio que México ha firmado a nivel internacional, como el Tratado de Libre Comercio de América del Norte, en la revisión por parte de las autoridades fiscales Mexicanas del cumplimiento de reglas de origen, así como el procedimiento de revisión a nivel administrativo y en las cortes federales.,", "Javier ha colaborado con las autoridades aduaneras en la redacción de reglas de procedimiento complementarias aplicables a los procesos de revisión de origen.", "A nivel local, ha participado en diversas ocasiones en negociaciones de estímulos fiscales con las autoridades fiscales de diversos Estados de la República Mexicana, en particular para el establecimiento de nuevas inversiones en nuestro país."],
        "qualifications": {
            "title": "Trayectoria",
            "items": [
                {
                    "title": "Educación",
                    "p": ["Abogado - Escuela Libre de Derecho (1988)", "Maestría en Derecho Tributario – Universidad Panamericana (1991)", "Postgrado en Asesoría Fiscal - Universidad Panamericana (1991)", "Programa Fiscal - Academia de Estudios Fiscales de la Contaduría Pública, A.C. (1991)", "Legal English and Orientation in the U.S. Legal System Program - International Law Institute - George Washington University Law School (1997)"]
                },
                {
                    "title": "Experiencia Académica",
                    "p": ["Javier ha sido Profesor de Derecho en la Escuela de Turismo de la Universidad Anáhuac y Profesor Adjunto de Derecho Romano en la Escuela Libre de Derecho."]
                },
                {
                    "title": "Publicaciones",
                    "p": ["• “Mexico, cahiers de droit fiscal international” (“Mexico: cases of international tax law”) Double non-taxation, Volumen 89a, publicado por International Fiscal Association, Congreso de Viena 2004."]
                }
            ]
        },
        "affiliations": {
            "title": "Asociaciones",
            "p": ["• Capítulo Mexicano de la Asociación Fiscal Internacional", "• Comité Fiscal Internacional del Colegio Mexicano de Contadores Públicos Titulados"]
        },
        "languages": {
            "title": "Idiomas",
            "p": ["• Español", "• Inglés"]
        }
    },
    "/partners/3": {
        "link": "/partners/3",
        "image": require('../../assets/equipo/Socios/Biografia/GustavoStruck_Esc.jpg'),
        "image_team": require('../../assets/equipo/Socios/Home/GustavoStruck_Esc.jpg'),
        "vcard": require('../../assets/vcards/Gustavo_Struck Creel.vcf'),
        "name": "Gustavo Struck Creel",
        "subtitle": "Socio",
        "description": [
            { name: "Derecho Corporativo y Mercantil", link: "/practice-areas#corporate-and-commercial-law" },
            { name: "Fusiones y Adquisiciones", link: "" },
            { name: "Derecho Financiero", link: "/practice-areas#finance" },
            { name: "Derecho Inmobiliario", link: "/practice-areas#real-estate" },
            { name: "Recursos Naturales", link: "/practice-areas#natural-resources" },
            { name: "ASG", link: "/practice-areas#esg" }],
        "contact": {
            "title": "Datos de contacto",
            "location": "Ciudad de México",
            "phone": "+52 (55) 1167-3108",
            "email": "gustavo.struck@creelabogados.com"
        },
        "p": ["Gustavo es Socio del Despacho. Su práctica la ha desarrollado en las áreas de Derecho Corporativo, Financiero e Inmobiliario. Gustavo representa a compañías nacionales y extranjeras dentro de una amplia gama de industrias en sus operaciones locales e internacionales, incluyendo operaciones de Fusiones y Adquisiciones, co-inversiones (<span style='font-family: 'Poppins Italic''>joint ventures</span>) y gobierno corporativo. Regularmente asesora a fondos de inversión, en sus procesos de formación y en operaciones de compraventa. Adicionalmente, cuenta con una activa práctica de Derecho Financiero, asesorando clientes en operaciones locales y transfronterizas de financiamiento corporativo, incluyendo créditos sindicados y financiamiento garantizado, con un sólido conocimiento del sector de entidades financieras no-bancarias. Su práctica en materia de Derecho Inmobiliario cubre una gran variedad de operaciones y participantes en la industria, destacando su experiencia en proyectos turísticos e industriales. Gustavo participa como abogado externo de múltiples oficinas familiares (<span style='font-family: Poppins Italic'>family offices</span>) y es miembro de varios Consejos de Administración y Comités."],
        "qualifications": {
            "title": "Trayectoria",
            "items": [
                {
                    "title": "Educación y Experiencia Profesional ",
                    "p": ["Licenciado en Derecho – Escuela Libre de Derecho (2001) ",
                        "Maestría en Derecho – Northwestern Pritzker School of Law (2002)",
                        "Asociado Internacional – Morrison & Foerster, San Francisco (2002-2003) "
                    ]
                },
                {
                    "title": "Publicaciones",
                    "p": ["• Capítulo “México” en “Corporate Governance 2022”, publicado por ICLG, editado por Adam O. Emmerich y Sebastian V. Niles, Wachtell, Lipton, Rosen & Katz, 2022.",
                        "• “Mexico: An Introduction to“Corporate/M&A”, publicado en Chambers and Partners Global Guide, 2022.",
                        "• Capítulo “México” en “Corporate Governance 2020“, publicado por ICLG, editado por Adam O. Emmerich y Sebastian V. Niles, Wachtell, Lipton, Rosen & Katz, 2020.",
                        "• “Relevant Issues Involving M&A of Mid-Size Mexican Companies”, publicado por Latin American Law & Business Report, Thomson Reuters, Agosto 2012.",
                        "• “Update to the Proposed Amendments to the Mexican Competition Law”, publicado por Latin American Law & Business Report, Thomson Reuters, Agosto 2010.",
                        "• “Structuring Real Estate Projects in Mexico, Recent Experience”, publicado por North American Free Trade & Investment Report, WorldTrade Executive, Marzo 2009.",
                        "• “SAPI’s as an Efficient Vehicle for Private Equity Investments in Mexico”, publicado por Latin American Law & Business Report, WorldTrade Executive, Abril 2007."
                    ]
                }
            ]
        },
        "affiliations": {
            "title": "Asociaciones",
            "p": ["American Bar Association"]
        },
        "languages": {
            "title": "Idiomas",
            "p": ["• Español", "• Inglés"]
        }
    },
    "/partners/5": {
        "link": "/partners/5",
        "image": require('../../assets/equipo/Socios/Biografia/CesarMorales_Esc.jpg'),
        "image_team": require('../../assets/equipo/Socios/Home/CesarMorales_Esc.jpg'),
        "vcard": require('../../assets/vcards/César_Morales G..vcf'),
        "name": "César Morales G.",
        "subtitle": "Socio",
        "description": [
            { name: "Derecho Corporativo y Mercantil", link: "/practice-areas#corporate-and-commercial-law" },
            { name: "Fusiones y Adquisiciones", link: "" },
            { name: "Derecho Inmobiliario", link: "/practice-areas#real-estate" },
            { name: "Derecho Financiero", link: "/practice-areas#finance" }],
        "contact": {
            "title": "Datos de contacto",
            "location": "Ciudad de México",
            "phone": "+52 (55) 1167-3038",
            "email": "cesar.morales@creelabogados.com"
        },
        "p": ["César es Socio del Despacho. Su práctica se enfoca en Derecho Corporativo, incluyendo Fusiones y Adquisiciones, Derecho Inmobiliario y Derecho Financiero, así como en asuntos relacionados con el área de Juegos y Sorteos y Derecho Deportivo. César tiene más de 20 años de experiencia y durante su activa carrera profesional ha asesorado a clientes nacionales y extranjeros en el establecimiento de sus operaciones en México, en una amplia variedad de co-inversiones (<span style='font-family: Poppins Italic)'>joint ventures</span>), fusiones y adquisiciones y reestructuras corporativas, en la formación de fondos y en proyectos inmobiliarios, así como en todo tipo de operaciones de financiamiento."],
        "qualifications": {
            "title": "Trayectoria",
            "items": [
                {
                    "title": "Educación y Experiencia Profesional",
                    "p": ["Abogado – Escuela Libre de Derecho (2004)",
                        "Maestría en Derecho Comercial Internacional – The London School of Economics and Political Science (2006)",
                        "Asociado Extranjero – Morrison & Foerster, Nueva York (2007-2008)"]
                }
            ]
        },
        "languages": {
            "title": "Idiomas",
            "p": ["• Español", "• Inglés"]
        }
    },
    "/partners/7": {
        "link": "/partners/7",
        "image": require('../../assets/equipo/Socios/Biografia/PabloArellano_Esc.jpg'),
        "image_team": require('../../assets/equipo/Socios/Home/PabloArellano_Esc.jpg'),
        "vcard": require('../../assets/vcards/Pablo_Arellano Q..vcf'),
        "name": "Pablo Arellano Q.",
        "subtitle": "Socio",
        "description": [{ name: "Derecho Corporativo y Mercantil", link: "/practice-areas#corporate-and-commercial-law" },
        { name: "Fusiones y Adquisiciones", link: "" },
        { name: "Derecho Financiero", link: "/practice-areas#finance" },
        { name: "Derecho Inmobiliario", link: "/practice-areas#real-estate" },
        { name: "ASG", link: "/practice-areas#esg" },
        { name: "Cumplimiento Regulatorio", link: "/practice-areas#compliance" }
        ],
        "contact": {
            "title": "Datos de contacto",
            "location": "Ciudad de México",
            "phone": "+52 (55) 1167-3040",
            "email": "pablo.arellano@creelabogados.com"
        },
        "p": ["Pablo es Socio del Despacho. Su práctica se ha centrado en Derecho Corporativo, incluyendo Fusiones y Adquisiciones, así como en Derecho Financiero y Derecho Inmobiliario. Pablo cuenta con amplia experiencia en operaciones de Fusiones y Adquisiciones y reestructuraciones corporativas, así como en el diseño, preparación y ejecución de operaciones de financiamiento, incluyendo créditos domésticos y transfronterizos, y estructuras de garantías. Asimismo, dentro de su práctica de Derecho Inmobiliario, Pablo asesora a clientes nacionales y extranjeros en la estructuración y negociación de contratos de fideicomiso, compraventa y arrendamiento de inmuebles."],
        "qualifications": {
            "title": "Trayectoria",
            "items": [
                {
                    "title": "Educación",
                    "p": [
                        "Licenciado en Derecho - Universidad Iberoamericana (2010)",
                        "Maestría en Derecho – University of California at Berkeley (2015)"]
                }
            ]
        },
        "languages": {
            "title": "Idiomas",
            "p": ["• Español", "• Inglés"]
        }
    },
    "/partners/8": {
        "link": "/partners/8",
        "image": require('../../assets/equipo/Socios/Biografia/ErnestoSaldate_Esc.jpg'),
        "image_team": require('../../assets/equipo/Socios/Home/ErnestoSaldate_Esc.jpg'),
        "vcard": require('../../assets/vcards/Ernesto_Saldate A..vcf'),
        "name": "Ernesto Saldate del Alto",
        "subtitle": "Socio",
        "description": [{ name: "Litigio y Resolución de Controversias", link: "/practice-areas#litigation-and-dispute-resolution" },
        { name: "Cumplimiento Regulatorio", link: "/practice-areas#compliance" }],
        "contact": {
            "title": "Datos de contacto",
            "location": "Ciudad de México",
            "phone": "+52 (55) 1167-3028",
            "email": "ernesto.saldate@creelabogados.com"
        },
        "p": ["Ernesto es Socio del Despacho. Se especializa en Litigio Civil y Mercantil, y Arbitraje. Su práctica abarca la atención de todo tipo de litigios complejos, ante tribunales locales y federales, incluyendo litigio constitucional, administrativo, civil y mercantil. Asimismo, Ernesto representa clientes en mecanismos alternativos de solución de conflictos (MASC) como mediación y arbitraje, participando en procedimientos ad hoc y ante las principales instituciones administradoras, nacionales y extranjeras. En coordinación con el Área de Práctica de Derecho Corporativo, Ernesto representa a clientes en el diseño e implementación de programas de prevención, gestión y remedición de cumplimiento normativo, así como en procesos de auditoría e investigaciones relacionadas."],
        "qualifications": {
            "title": "Trayectoria",
            "items": [
                {
                    "title": "Educación",
                    "p": ["Licenciado en Derecho - Universidad Panamericana (2001)",
                        "Especialidad en Derecho Mercantil y Corporativo – Escuela Libre de Derecho (2007)",
                        "Especialidad en Derecho Penal – Escuela Libre de Derecho (2008)",
                        "Especialidad en Derecho Procesal Civil y Mercantil – Universidad Panamericana (2009)",
                        "Especialidad en Derecho Civil y Mercantil – Universidad Panamericana (2010)"
                    ]
                },
                {
                    "title": "Publicaciones",
                    "p": ["• “Shareholders claims against M&A transactions in Mexico”, publicado por Lexology: Getting the Deal Through, Abril 2022.",
                        "• “M&A Litigation in Mexico”, publicado por Lexology: Getting the Deal Through, Abril 2021."
                    ]
                }
            ]
        },
        "languages": {
            "title": "Idiomas",
            "p": ["• Español", "• Inglés"]
        }
    },
    "/partners/9": {
        "link": "/partners/9",
        "image": require('../../assets/equipo/Socios/Biografia/Guillermo_Bio.jpg'),
        "image_team": require('../../assets/equipo/Socios/Home/Guillermo_Home.jpg'),
        "vcard": "",
        "name": "Guillermo Rocha L.",
        "subtitle": "Socio",
        "description": [
            { name: "Derecho Corporativo y Mercantil", link: "/practice-areas#corporate-and-commercial-law" },
            { name: "Fusiones y Adquisiciones", link: "" },
            { name: "Derecho Financiero", link: "/practice-areas#finance" },
            { name: "Cumplimiento Regulatorio", link: "/practice-areas#compliance" }
        ],
        "contact": {
            "title": "Datos de contacto",
            "location": "Ciudad de México",
            "phone": "+52 (55) 1167-3039",
            "email": "guillermo.rocha@creelabogados.com"
        },
        "p": ["Guillermo es Socio del Despacho. Guillermo se especializa en Derecho Corporativo y Derecho Financiero, con un enfoque particular en operaciones de financiamiento estructurado y capital privado. Tiene más de 25 años de experiencia participando en operaciones como asesor legal, estructurador y principal. Guillermo ha sido Director de Estructuración y Director Jurídico de varios fondos de deuda privada, y Director General y Fundador de intermediarios financieros no bancarios. Guillermo participa en varios Consejos de Administración y Comités."],
        "qualifications": {
            "title": "Trayectoria",
            "items": [
                {
                    "title": "Educación",
                    "p": ["Licenciado en Derecho - Instituto Tecnológico Autónomo de México (2002)",
                        "Maestría en Derecho – Northwestern University School of Law (con honores) (2006)",
                        "Certificado en Administración de Empresas - Kellogg School of Management (2006)",
                        "Asociado Internacional - Gibson, Dunn & Crutcher, Nueva York (2007-2008)"
                    ]
                }
            ]
        },
        "languages": {
            "title": "Idiomas",
            "p": ["• Español", "• Inglés"]
        }
    },
    "/advisers/1": {
        "link": "/advisers/1",
        "image": require('../../assets/equipo/Consejeros/Biografia/LuisCreel_Esc.jpg'),
        "image_team": require('../../assets/equipo/Consejeros/Home/LuisJCreel_Esc.jpg'),
        "vcard": require('../../assets/vcards/Luis_J. Creel C..vcf'),
        "name": "Luis J. Creel Carrera",
        "subtitle": "Consejero Honorario",
        "description": [""],
        "contact": {
            "title": "Datos de contacto",
            "location": "Ciudad de México",
            "phone": "+52 (55) 1167-3000",
            "email": "luis.creel@creelabogados.com"
        },
        "p": ["Luis es Consejero Honorario y fundador del Despacho, junto con Don Luis J. Creel Luján. Durante su larga y activa práctica profesional Luis se especializó en Derecho Corporativo, habiendo representado a un amplio número de los grupos industriales y financieros más relevantes en México, y a corporaciones extranjeras líderes en sus operaciones y proyectos estratégicos dentro de México. Ha sido fundador, presidente y miembro del Consejo de Administración de múltiples instituciones privadas y listadas en la Bolsa Mexicana de Valores, incluyendo IXE Grupo Financiero (hoy parte de Grupo Financiero Banorte). Asimismo, Luis participó activamente en la formación, desarrollo y consolidación de obras y fundaciones de carácter social, como lo son Fundación Casa Alianza México (Mexican affiliate of Covenant House), en donde actuó como Presidente de su Patronato por dos décadas, así como de Fomento de Investigación y Cultura Superior y Patronato Económico y de Desarrollo de la Universidad Panamericana."],
        "qualifications": {
            "title": "Trayectoria",
            "items": [
                {
                    "title": "Educación y Experiencia Profesional",
                    "p": ["Abogado - Escuela Libre de Derecho (1966)", "Programa de Introducción al Sistema Legal Americano – Universidad de Princeton (1966)", "Maestría en Derecho – Universidad de Harvard (1967)", "Asociado Extranjero – Hill & Barlow, Boston (1967-1968)", "Postgrado de Administración de Empresas y Finanzas – Universidad de las Américas (1971-1972)", "Programa de Alta Dirección AD-2 – IPADE (1972-1973)", "Diploma de Especialización en Derecho Fiscal – Universidad Panamericana (1979)"]
                },
            ]
        },
        "affiliations": {
            "title": "Asociaciones",
            "p": ["Barra Mexicana, Colegio de Abogados", "Ilustre y Nacional Colegio de Abogados"]
        },
        "languages": {
            "title": "Idiomas",
            "p": ["• Español", "• Inglés"]
        }
    },
    "/advisers/2": {
        "link": "/advisers/2",
        "image": require('../../assets/equipo/Consejeros/Biografia/JorgeTorres_esc.jpg'),
        "image_team": require('../../assets/equipo/Consejeros/Home/JorgeTorresB_Esc.jpg'),
        "vcard": require('../../assets/vcards/Jorge_Torres B..vcf'),
        "name": "Jorge Torres B.",
        "subtitle": "Consejero Senior",
        "description": [
            { name: "Derecho Inmobiliario", link: "/practice-areas#real-estate" },
            { name: "Derecho Corporativo y Mercantil", link: "/practice-areas#corporate-and-commercial-law" }],
        "contact": {
            "title": "Datos de contacto",
            "location": "Ciudad de México",
            "phone": "+52 (55) 1167-3010 ",
            "email": "jorge.torres@creelabogados.com"
        },
        "p": ["Jorge es Consejero Senior del Despacho. Durante su larga trayectoria, Jorge ha desarrollado una práctica de Derecho Inmobiliario excepcional. Ha participado en los asuntos y proyectos inmobiliarios de mayor relevancia en México, representando activamente a importantes grupos mexicanos e internacionales en la adquisición, arrendamiento y desarrollo de propiedades inmobiliarias para los sectores turístico, comercial, industrial y residencial en diversas regiones del país. Asimismo, Jorge cuenta con una amplia experiencia liderando numerosas operaciones de naturaleza corporativa y comercial, incluyendo Fusiones y Adquisiciones, representando a clientes en diversos sectores industriales."],
        "qualifications": {
            "title": "Trayectoria",
            "items": [
                {
                    "title": "Educación y Experiencia Profesional",
                    "p": ["Abogado - Escuela Libre de Derecho (1985)",
                        "Postgrado en Arbitraje Comercial Internacional - Escuela Libre de Derecho (1995)",
                        "Asociado Extranjero - Gardere & Wynne, Dallas (1988)"
                    ]
                },
                {
                    "title": "Publicaciones",
                    "p": ["• Capítulo “México” en PLC Cross-border Corporate Real Estate Handbook, publicado por Practical Law Company, primera a quinta y séptima a novena ediciones (2004/5 a 2007/8, 2010/11 a 2012/13).",
                        "• Capítulo “México” en The Real Estate Law Review, Law Business Research, 2012.",
                        "• “Derecho Inmobiliario, Temas Selectos y Perspectivas del Sector”, publicado por Editorial Porrúa e Instituto Tecnológico Autónomo de México, 2010.",
                        "• “Overview of Mexican Real Estate Investments” en PLC Cross-border Corporate Real Estate Handbook, publicado por Practical Law Company, Cuarta Edición 2009.",
                        "• “Real Estate Investments in Mexico” en PLC Cross-border Corporate Real Estate Handbook, publicado por Practical Law Company, Tercera Edición, 2008."
                    ]
                }
            ]
        },
        "languages": {
            "title": "Idiomas",
            "p": ["• Español", "• Inglés"]
        }
    },
    "/advisers/3": {
        "link": "/advisers/3",
        "image": require('../../assets/equipo/Consejeros/Biografia/AlejandroCreel_Esc.jpg'),
        "image_team": require('../../assets/equipo/Consejeros/Home/Alejandro_Esc.jpg'),
        "vcard": require('../../assets/vcards/Alejandro_Creel Y..vcf'),
        "name": "Alejandro Creel Y.",
        "subtitle": "Consejero",
        "description": [
            { name: "Derecho Corporativo y Mercantil", link: "/practice-areas#corporate-and-commercial-law" },
            { name: "Fusiones y Adquisiciones", link: "" },
            { name: "Derecho Inmobiliario", link: "/practice-areas#real-estate" },
            { name: "Recursos Naturales", link: "/practice-areas#natural-resources" }],
        "contact": {
            "title": "Datos de contacto",
            "location": "Ciudad de México",
            "phone": "+52 55-1167-3041, ext. 160",
            "email": "alejandro.creel@creelabogados.com"
        },
        "p": ["Alejandro es Consejero del Despacho. Ha desarrollado su práctica en Derecho Corporativo y Derecho Inmobiliario. Alejandro concentra su práctica Corporativa en la asesoría a empresas y fondos nacionales e internacionales en operaciones de Fusiones y Adquisiciones, reestructuras corporativas, co-inversiones (<span style='font-family: Poppins Italic'>joint ventures</span>) y gobierno corporativo. En el campo de Derecho Inmobiliario, Alejandro participa activamente en operaciones inmobiliarias complejas, representando a inversionistas, desarrolladores y operadores en la estructuración de contratos de fideicomiso, compraventa y arrendamiento de inmuebles, así como en la constitución de regímenes de propiedad en condominio, tiempo compartido y propiedad fraccionada."],
        "qualifications": {
            "title": "Trayectoria",
            "items": [
                {
                    "title": "Educación",
                    "p": ["Licenciado en Derecho - Universidad Iberoamericana (2006)",
                        "Maestría en Derecho – Instituto de Empresa (IE), Madrid, España (2009)"
                    ]
                }
            ]
        },
        "languages": {
            "title": "Idiomas",
            "p": ["• Español", "• Inglés"]
        }
    },
    "/advisers/4": {
        "link": "/advisers/4",
        "image": require('../../assets/equipo/Consejeros/Biografia/AlejandroGorozpe_Bio.jpg'),
        "image_team": require('../../assets/equipo/Consejeros/Home/AlejandroGorozpe_Home.jpg'),
        "vcard": "",
        "name": "Alejandro Gorozpe Z.",
        "subtitle": "Consejero",
        "description": [
            { name: "Derecho Laboral", link: "/practice-areas#labor-and-employment" }],
        "contact": {
            "title": "Datos de contacto",
            "location": "Ciudad de México",
            "phone": "+52 (55) 1167-3000",
            "email": "alejandro.gorozpe@creelabogados.com"
        },
        "p": ["Alejandro es Consejero del Despacho. Alejandro se especializa en Derecho Laboral. Cuenta con más de 15 años de experiencia representando activamente a empresas en consultoría general sobre asuntos laborales y de seguridad social, incluyendo contratación y terminación de empleados, transferencias de empleados como consecuencia de compraventas de activos y fusiones, e implicaciones laborales de operaciones de Fusiones y Adquisiciones, así como en la redacción y negociación de contratos colectivos de trabajo. Alejandro asimismo se enfoca en temas de litigio en los Tribunales Laborales."],
        "qualifications": {
            "title": "Trayectoria",
            "items": [
                {
                    "title": "Educación",
                    "p": ["Licenciado en Derecho – Universidad Anáhuac (2007)",
                        "Postgrado en el Sistema Legal Americano – Boston University (2008)",
                        "Diploma en Negociación – Harvard University (2013)"
                    ]
                }
            ]
        },
        "languages": {
            "title": "Idiomas",
            "p": ["• Español", "• Inglés"]
        }
    },
    "/associates/1": {
        "link": "/associates/1",
        "image": require('../../assets/equipo/Asociados/Biografía/DiegoCorvera_Bio.jpg'),
        "image_team": require('../../assets/equipo/Asociados/Home/DiegoCorvera_Home.jpg'),
        "vcard": "",
        "name": "Diego Corvera H.",
        "subtitle": "Asociado",
        "description": [
            { name: "Derecho Corporativo y Mercantil", link: "/practice-areas#corporate-and-commercial-law" },
            { name: "Fusiones y Adquisiciones", link: "" },
            { name: "Derecho Financiero", link: "/practice-areas#finance" },
        ],
        "contact": {
            "title": "Datos de contacto",
            "location": "Ciudad de México",
            "phone": "+52 (55) 1167-3010, ext. 3033",
            "email": "diego.corvera@creelabogados.com"
        },
        "p": ["Diego se especializa en Derecho Corporativo y Financiero. Concentra su práctica en operaciones de Fusiones y Adquisiciones nacionales e internacionales, reestructuraciones corporativas, y en asuntos corporativos y comerciales. Asimismo, enfoca su práctica en todo tipo de operaciones financieras, incluyendo financiamiento corporativo, y en proyectos de mercados de capitales y deuda. De igual forma, Diego brinda asesoría jurídica en materia de Seguros, en la revisión de contratos y la atención de consultas sobre aspectos regulatorios de instituciones de seguro."],
        "qualifications": {
            "title": "Trayectoria",
            "items": [
                {
                    "title": "Educación",
                    "p": ["Licenciado en Derecho – Universidad Anáhuac (2022)",
                        "Maestría en Negocios Internacionales - Hult International Business School, Londres, Reino Unido (2024)",
                        "Diploma en Arbitraje - The International Academy for Arbitration Law. Paris, Francia (2024)"]
                }
            ]
        },
        "languages": {
            "title": "Idiomas",
            "p": ["• Español", "• Inglés"]
        }
    },
    "/associates/4": {
        "link": "/associates/4",
        "image": require('../../assets/equipo/Asociados/Biografía/Tsen_Esc.jpg'),
        "image_team": require('../../assets/equipo/Asociados/Home/Tsen_Esc.jpg'),
        "vcard": require('../../assets/vcards/Tsen-Fang Lin_.vcf'),
        "name": "Tsen Fang-Lin",
        "subtitle": "Asociada",
        "description": [
            { name: "Derecho Corporativo y Mercantil", link: "/practice-areas#corporate-and-commercial-law" },
            { name: "Fusiones y Adquisiciones", link: "" },
            { name: "Derecho Financiero", link: "/practice-areas#finance" },
        ],
        "contact": {
            "title": "Datos de contacto",
            "location": "Ciudad de México",
            "phone": "+52 (55) 1167-3000 ext. 159",
            "email": "tsen-fang.lin@creelabogados.com"
        },
        "p": ["Lin se especializa en Derecho Corporativo y Financiero. Lin asesora clientes nacionales y extranjeros en asuntos corporativos y mercantiles, incluyendo el mantenimiento diario de sus sociedades en México, la redacción y negociación de una variedad amplia de contratos y la atención de operaciones de Fusiones y Adquisiciones. Asimismo, la práctica de Lin comprende la asesoría en operaciones de financiamientos, incluyendo créditos domésticos y transfronterizos y su correspondiente estructura de garantías."],
        "qualifications": {
            "title": "Trayectoria",
            "items": [
                {
                    "title": "Educación",
                    "p": ["Abogado – Escuela Libre de Derecho (2019)"]
                }
            ]
        },
        "languages": {
            "title": "Idiomas",
            "p": ["• Español", "• Inglés", "• Mandarín", "• Taiwanés Minnan"]
        }
    },
    "/associates/6": {
        "link": "/associates/6",
        "image": require('../../assets/equipo/Asociados/Biografía/SandraLopez_Esc.jpg'),
        "image_team": require('../../assets/equipo/Asociados/Home/SandraLopez_Esc.jpg'),
        "vcard": require('../../assets/vcards/Sandra_López CH..vcf'),
        "name": "Sandra López Ch.",
        "subtitle": "Asociada",
        "description": [{ name: "Derecho Corporativo y Mercantil", link: "/practice-areas#corporate-and-commercial-law" },
        { name: "Fusiones y Adquisiciones", link: "" },
        { name: "Derecho Financiero", link: "/practice-areas#finance" },
        { name: "Cumplimiento Regulatorio", link: "/practice-areas#compliance" }],
        "contact": {
            "title": "Datos de contacto",
            "location": "Ciudad de México",
            "phone": "+52 (55) 1167-3010, ext. 117",
            "email": "sandra.lopez@creelabogados.com"
        },
        "p": ["Sandra se especializa en Derecho Corporativo. Sandra asesora clientes nacionales y extranjeros en asuntos corporativos y mercantiles, incluyendo el mantenimiento diario de sus sociedades, y en la elaboración y negociación de una amplia variedad de contratos relacionados con su operación ordinaria. Su práctica abarca la atención de consultas en el cumplimiento regulatorio <span style='font-family: Poppins Italic'>(compliance)</span> en materia de inversión extranjera, protección de datos personales, anti-lavado de dinero y anti-corrupción. Sandra asimismo cuenta con experiencia en Derecho Financiero, habiendo participado activamente en una amplia variedad de operaciones de financiamiento local y transfronterizo, así como en la atención de consultas en materia de regulación financiera."],
        "qualifications": {
            "title": "Trayectoria",
            "items": [
                {
                    "title": "Educación",
                    "p": ["Licenciado en Derecho – Universidad Iberoamericana (2017)",
                        "Especialidad y Maestría en Derecho Privado - Universidad Anáhuac (2017–2019)"
                    ]
                }
            ]
        },
        "languages": {
            "title": "Idiomas",
            "p": ["• Español", "• Inglés"]
        }
    },
    "/associates/8": {
        "link": "/associates/8",
        "image": require('../../assets/equipo/Asociados/Biografía/FernandaMelgar_Esc.jpg'),
        "image_team": require('../../assets/equipo/Asociados/Home/MariaFernandaMelgar_Esc.jpg'),
        "vcard": require('../../assets/vcards/María_Fernanda Melgar G..vcf'),
        "name": "Fernanda Melgar G.",
        "subtitle": "Asociada",
        "description": [
            { name: "Derecho Corporativo y Mercantil", link: "/practice-areas#corporate-and-commercial-law" },
            { name: "Fusiones y Adquisiciones", link: "" },
            { name: "Derecho Financiero", link: "/practice-areas#finance" },
            { name: "Competencia Económica", link: "" }],
        "contact": {
            "title": "Datos de contacto",
            "location": "Ciudad de México",
            "phone": "+52 (55) 1167-3010, ext.131",
            "email": "fernanda.melgar@creelabogados.com"
        },
        "p": ["Fernanda se especializa en Derecho Corporativo y Derecho Financiero. Fernanda concentra su práctica en operaciones de Fusiones y Adquisiciones, reestructuraciones corporativas, asuntos corporativos y mercantiles en general, el mantenimiento diario de sociedades, incluyendo cumplimiento <span style='font-family: Poppins Italic'>(compliance)</span> en materia de inversión extranjera y regulatoria, así como la coordinación de auditorías. Asimismo, Fernanda brinda asesoría en temas regulatorios de Derecho Bancario (<span style='font-family: Poppins Italic'>FinTech</span>) y participa en la estructuración de contratos de crédito y de garantía. Fernanda presta asesoría en asuntos de Competencia Económica, incluyendo la representación de compañías en procesos de notificación de concentraciones ante la Comisión Federal de Competencia Económica."],
        "qualifications": {
            "title": "Trayectoria",
            "items": [
                {
                    "title": "Educación",
                    "p": ["Licenciado en Derecho - Universidad Iberoamericana (2020)"]
                }
            ]
        },
        "languages": {
            "title": "Idiomas",
            "p": ["• Español", "• Inglés"]
        }
    },
    "/associates/9": {
        "link": "/associates/9",
        "image": require('../../assets/equipo/Asociados/Biografía/MoisesProchovnik_Esc.jpg'),
        "image_team": require('../../assets/equipo/Asociados/Home/Moises_Esc.jpg'),
        "vcard": require('../../assets/vcards/Moisés_Prochovnik A..vcf'),
        "name": "Moisés Prochovnik A. ",
        "subtitle": "Asociado",
        "description": [
            { name: "Derecho Corporativo y Mercantil", link: "/practice-areas#corporate-and-commercial-law" },
            { name: "Fusiones y Adquisiciones", link: "" },
            { name: "Derecho Financiero", link: "/practice-areas#finance" }],
        "contact": {
            "title": "Datos de contacto",
            "location": "Ciudad de México",
            "phone": "+52 (55) 1167-3010, ext. 122",
            "email": "moises.prochovnik@creelabogados.com"
        },
        "p": ["Moisés se especializa en Derecho Corporativo y Derecho Financiero. Moisés concentra su práctica en operaciones de Fusiones y Adquisiciones, reestructuraciones corporativas, emisiones de valores en bolsa, preparación y negociación de contratos de créditos y asesoría a todo tipo de entidades financieras. Asimismo, brinda asesoría en la estructuración de contratos de fideicomisos y contratos de compraventa de acciones. Cuenta con amplia experiencia en asesorar compañías e instituciones financieras en operaciones bursátiles, incluyendo ofertas privadas y públicas de valores, y en asuntos regulatorios ante la Bolsa Mexicana de Valores, BIVA y la Comisión Nacional Bancaria y de Valores."],
        "qualifications": {
            "title": "Trayectoria",
            "items": [
                {
                    "title": "Educación y Experiencia Profesional",
                    "p": ["Abogado – Escuela Libre de Derecho (2017)",
                        "Diplomado en Seguros y Fianzas – Escuela Libre de Derecho (2015)",
                        "Maestría en Derecho – Columbia University (2022)",
                        "Actualmente, colabora como Asociado Extranjero en Winston & Strawn LLP, Nueva York"
                    ]
                },
                {
                    "title": "Experiencia Académica",
                    "p": ["Moisés ha sido Profesor Adjunto en la materia de Derecho Bursátil en la Escuela Libre de Derecho."]
                }
            ]
        },
        "languages": {
            "title": "Idiomas",
            "p": ["• Español", "• Inglés", "• Hebreo"]
        }
    },
    "/associates/10": {
        "link": "/associates/10",
        "image": require('../../assets/equipo/Asociados/Biografía/JonathanSegura_Esc.jpg'),
        "image_team": require('../../assets/equipo/Asociados/Home/JonathanSegura_Esc.jpg'),
        "vcard": require('../../assets/vcards/Jonathan_Segura M..vcf'),
        "name": "Jonathan Segura M.",
        "subtitle": "Asociado",
        "description": [{ name: "Litigio y Solución de Controversias", link: "/practice-areas#litigation-and-dispute-resolution" }],
        "contact": {
            "title": "Datos de contacto",
            "location": "Ciudad de México",
            "phone": "+52 (55) 1167-3010, ext. 118",
            "email": "jonathan.segura@creelabogados.com"
        },
        "p": ["Jonathan se especializa en Litigio Civil y Mercantil. Jonathan concentra su práctica en todo tipo de procedimientos y litigio civil, mercantil, administrativo y constitucional, así como en asuntos de mediación, conciliación y otros medios alternos de solución de controversias. Cuenta con experiencia en juicios ordinarios y especiales en las distintas instancias judiciales y administrativas. Asimismo, Jonathan brinda asesoría en elaboración de demandas, ofrecimiento y valoración de pruebas, y medios de impugnación, así como en la elaboración de contratos civiles y mercantiles, incluyendo compraventas, arrendamientos, hipotecas y fideicomisos, y reconocimiento y pago de deudas."],
        "qualifications": {
            "title": "Trayectoria",
            "items": [
                {
                    "title": "Educación",
                    "p": ["Licenciado en Derecho - Universidad Nacional Autónoma de México (2021)"
                    ]
                }
            ]
        },
        "languages": {
            "title": "Idiomas",
            "p": ["• Español", "• Inglés"]
        }
    },
    "/associates/11": {
        "link": "/associates/11",
        "image": require('../../assets/equipo/Asociados/Biografía/PabloTorres_Esc.jpg'),
        "image_team": require('../../assets/equipo/Asociados/Home/PabloTorres_Esc.jpg'),
        "vcard": require('../../assets/vcards/Pablo_Torres A..vcf'),
        "name": "Pablo Torres A.",
        "subtitle": "Asociado",
        "description": [
            { name: "Derecho Corporativo y Mercantil", link: "/practice-areas#corporate-and-commercial-law" },
            { name: "Fusiones y Adquisiciones", link: "" },
            { name: "Cumplimiento Regulatorio", link: "/practice-areas#compliance" }],
        "contact": {
            "title": "Datos de contacto",
            "location": "Ciudad de México",
            "phone": "+52 (55) 1167-3010, ext. 123",
            "email": "pablo.torres@creelabogados.com"
        },
        "p": ["Pablo se especializa en Derecho Corporativo y Mercantil. Pablo concentra su práctica en asuntos corporativos y mercantiles en general, incluyendo reestructuraciones corporativas, co-inversiones (joint ventures) y gobierno corporativo, y asimismo asesora a clientes en cumplimiento de las regulaciones de inversión extranjera, protección de datos personales y prevención de lavado de dinero. La práctica de Pablo comprende asesoría corporativa transaccional, particularmente en Fusiones y Adquisiciones. Asimismo, cuenta con experiencia en materia de Propiedad Industrial, incluyendo el registro y mantenimiento de derechos de propiedad industrial, así como en auditorías marcarias."],
        "qualifications": {
            "title": "Trayectoria",
            "items": [
                {
                    "title": "Educación",
                    "p": ["Licenciado en Derecho – Instituto Tecnológico y de Estudios Superiores de Occidente (ITESO) (2021)",
                        "Diplomado en Derecho Corporativo – Instituto Tecnológico Autónomo de México (2022)",
                        "Maestría en Derecho – University of Aberdeen, Reino Unido (2024)"
                    ]
                }
            ]
        },
        "languages": {
            "title": "Idiomas",
            "p": ["• Español", "• Inglés", "• Alemán"]
        }
    },
}
export default es