import React, { useState, useContext, useEffect } from 'react';
import { LanguageContext } from '../../context/LanguageContext'

import { getArticles } from '../../utils/articles';

import MainLayout from '../../Layout/MainLayout'
import esLang from '../../translations/recursos/es.js'
import enLang from '../../translations/recursos/en.js'
import HeroContainer from './HeroContainer'
import HighLightContainer from './HighlightContainer';
import ArticlesContainer from './ArticlesContainer';

function Recursos(params) {
    const [articles, setArticles] = useState([])
    const [legalNotes, setLegalNotes] = useState([])

    const { language } = useContext(LanguageContext);
    const translation = language === 'es' ? esLang : enLang

    useEffect(() => {
        window.scrollTo(0, 0);
        getArticles()
            .then((res) => { 

                if(language == 'es') {
                    const notas = res.legalnotes.filter((notes) => notes.idioma == 1);
                    const articulos = res.articulos.filter((notes) => notes.idioma == 1)
                    setLegalNotes(notas)
                    setArticles(articulos) 
                }
                if(language == 'en'){
                    const notas = res.legalnotes.filter((notes) => notes.idioma == 2);
                    const articulos = res.articulos.filter((notes) => notes.idioma == 2)
                    setLegalNotes(notas)
                    setArticles(articulos) 
                }
            })
            .catch(error => { console.log(error) })
    }, []);

    const articlesEsp = {title: language == 'es' ? "Artículos" : 'Articles', more: language == 'es' ? "Ver todo" : 'See more', items: articles }
    const legalNotesEsp = {title: language == 'es' ? "Notas Legales" : 'Legal Notes', more:language == 'es' ?  "Ver todo" : 'See more', items: legalNotes }

    return (
        <MainLayout>
            <HeroContainer items={translation} />
            {/* <HighLightContainer items={translation["highlight"]} /> */}
            <div className='pb-[120px]'>
                <ArticlesContainer items={articlesEsp} />
                <ArticlesContainer items={legalNotesEsp} />
            </div>
        </MainLayout>
    )

}
export default Recursos