const en = {
    "/partners/1": {
        "link": "/partners/1",
        "image": require('../../assets/equipo/Socios/Biografia/CarlosCreel_Esc.jpg'),
        "image_team": require('../../assets/equipo/Socios/Home/CarlosCreel_Esc.jpg'),
        "vcard": require('../../assets/vcards/Carlos_Creel C..vcf'),
        "name": "Carlos Creel C.",
        "subtitle": "Senior Partner",
        "description": [
            { name: "Corporate and Commercial", link: "/practice-areas#corporate-and-commercial-law" },
            { name: "Finance", link: "/practice-areas#finance" },
            { name: "Mergers and Acquisitions", link: "" }],
        "contact": {
            "title": "Contact",
            "location": "Mexico City",
            "phone": "+52 (55) 1167-3010",
            "email": "carlos.creel@creelabogados.com"
        },
        "p": ["Carlos is the Senior Partner of our Firm. His professional practice focuses on general corporate work, and banking and securities law matters. His corporate practice includes advising public and private corporations and other entities in a variety of industries in connection with M&A, corporate governance matters, cross-border joint ventures and strategic alliances, as well as private equity and debt funds in their formation and a variety of transactional matters. He also has extensive experience in corporate finance transactions, including secured financing and syndicated lending, as well as in capital markets transactions, representing issuers and investment banks in a broad range of public and private transactions, including initial public offerings, secondary offerings and cross-border securities offerings.",
            "Carlos represents family investment offices and family-owned or controlled businesses on both specialized and strategic matters, including succession planning and generational transition. Carlos also regularly and actively advises not-for-profit institutions on governance and related matters. He is a member of the Boards and Executive Committees of Fundación Empresarios por la Educación Básica (ExEb) and Alianza Médica para la Salud (AMSA)."],
        "qualifications": {
            "title": "Qualifications",
            "items": [
                {
                    "title": "Education and Professional Experience ",
                    "p": ["Law Degree - Escuela Libre de Derecho (1984)", "Foreign Associate - Shearman & Sterling, New York (1984-1985)", "Senior Management Program AD-2 – IPADE (2018)"]
                },
                {
                    "title": "Academic Experience ",
                    "p": ["Carlos has been a Professor of Corporate Law at the Law Faculty of the Instituto Tecnológico Autónomo de México (ITAM), Adjunct Professor of International Private Law at the Escuela Libre de Derecho and since 2011 is Professor of Corporate Governance and Private Equity in the LL.M. Program of the Universidad Panamericana."]
                },
                {
                    "title": "Publications",
                    "p": ["Carlos has published articles and essays on a wide variety of fields of Mexican law, including corporate governance and private equity:",
                        "“México” Chapter in 'Corporate Governance 2022', published by ICLG, edited by Adam O. Emmerich and Sebastian V. Niles, Wachtell, Lipton, Rosen & Katz, 2022.",
                        "Among the recent articles he has written, the following are noted:",
                        "• “México” Chapter in 'Corporate Governance 2020', published by ICLG, edited by Adam O. Emmerich and Sebastian V. Niles, Wachtell, Lipton, Rosen & Katz, 2020.",
                        "• “Mexico” Chapter in “NYSE: Corporate Governance Guide”, published in association with the New York Stock Exchange, edited by Steven A. Rosenblum, Karessa L. Cain and Sabastian V. Niles, Wachtell, Lipton, Rosen & Katz, 2015.",
                        "• “Mexico” in “Corporate Laws of the World” - “Gesellschaftsrecht des Auslands”, edited by Gerhard Wegen, Andreas Spahlinger and Marcel Barth, Gleiss Lutz, published by Verlag C.H.BECK, 2013.",
                        "• “La Sociedad Anónima Promotora de Inversión”, published in Obra Jurídica Enciclopédica en Homenaje a la Escuela Libre de Derecho en su Primer Centenario, Derecho Mercantil, by Editorial Porrua, México, 2012.",
                        "• “Duties and Liabilities of Directors of Mexican Stock Corporations”, published in The International Who's Who of Corporate Governance Lawyers, 2010.",
                    ]
                }
            ]
        },
        "affiliations": {
            "title": "Memberships & Affiliations",
            "p": ["• Mexican Bar Association", "• Ilustre y Nacional Colegio de Abogados"]
        },
        "languages": {
            "title": "Languages",
            "p": ["• Spanish", "• English"]
        }
    },
    "/partners/2": {
        "link": "/partners/2",
        "image": require('../../assets/equipo/Socios/Biografia/JavierPortas_Esc.jpg'),
        "image_team": require('../../assets/equipo/Socios/Home/JavierPortas_Esc.jpg'),
        "vcard": require('../../assets/vcards/Javier_Portas L..vcf'),
        "name": "Javier Portas L.",
        "subtitle": "Partner",
        "description": [{ name: "Tax", link: "/practice-areas#tax" }, { name: "Compliance", link: "/practice-areas#compliance" }],
        "contact": {
            "title": "Contact",
            "location": "Mexico City",
            "phone": "+52 (55) 1167-3037",
            "email": "javier.portas@creelabogados.com"
        },
        "p": ["Javier is a Partner of our Firm. He focuses his practice on Mexican and international corporate taxation. He provides corporate tax and international tax advice to foreign and domestic corporations, including partnerships, limited liability companies, joint venture entities, trusts and funds. Javier also represents clients in tax litigation at federal and local courts. His practice also includes international trade matters and the application of several Foreign Trade Agreements entered into by Mexico, such as the North American Free Trade Agreement. He represents clients in connection with the review by Mexican tax authorities of compliance with determination of rules of origin and procedures followed to challenge the authorities’ determination, both administratively, as well as before federal courts. Javier has collaborated with federal customs authorities in drafting complementary procedural rules applicable to origin determination review processes. At the state level, he has participated in several negotiations of tax benefits with local tax authorities in several States, mainly in connection with the establishment of new investments in Mexico."],
        "qualifications": {
            "title": "Qualifications",
            "items": [
                {
                    "title": "Education",
                    "p": [
                        "Law Degree- Escuela Libre de Derecho (1988)",
                        "LL.M Degree in Tax Law – Universidad Panamericana (1991)",
                        "Postgraduate Degree in Tax Counseling - Universidad Panamericana (1991)",
                        "Tax Law Program - Academia de Estudios Fiscales de la Contaduría Pública, A.C. (1991)",
                        "Legal English and Orientation in the U.S. Legal System Program - International Law Institute - George Washington University Law School (1997)"
                    ]
                },
                {
                    "title": "Academic Experience",
                    "p": ["Javier has been a Professor of Law at the Tourism Faculty of the Universidad Anahuac and Assistant Professor of Roman Law at the Escuela Libre de Derecho."]
                },
                {
                    "title": "Publications",
                    "p": ["• “Mexico, cahiers de droit fiscal international” (“Mexico: cases of international tax law”) Double non-taxation, Volume 89a, published by the International Fiscal Association, 2004 Vienna Congress."]
                }
            ]
        },
        "affiliations": {
            "title": "Memberships & Affiliations ",
            "p": ["• Mexican Chapter of the International Tax Association ",
                "• International Tax Committee of the Colegio Mexicano de Contadores Públicos Titulados"]
        },
        "languages": {
            "title": "Languages",
            "p": ["• Spanish", "• English"]
        }
    },
    "/partners/3": {
        "link": "/partners/3",
        "image": require('../../assets/equipo/Socios/Biografia/GustavoStruck_Esc.jpg'),
        "image_team": require('../../assets/equipo/Socios/Home/GustavoStruck_Esc.jpg'),
        "vcard": require('../../assets/vcards/Gustavo_Struck Creel.vcf'),
        "name": "Gustavo Struck Creel",
        "subtitle": "Partner",
        "description": [
            { name: "Corporate and Commercial", link: "/practice-areas#corporate-and-commercial-law" },
            { name: "Mergers and Acquisitions", link: "" },
            { name: "Finance", link: "/practice-areas#finance" },
            { name: "Real Estate", link: "/practice-areas#real-estate" },
            { name: "Natural Resources", link: "/practice-areas#natural-resources" },
            { name: "ESG", link: "/practice-areas#esg" }],
        "contact": {
            "title": "Contact",
            "location": "Mexico City",
            "phone": "+52 (55) 1167-3108",
            "email": "gustavo.struck@creelabogados.com"
        },
        "p": ["Gustavo is a Partner of our Firm. He specializes in Corporate Law, Finance and Real Estate. Gustavo represents Mexican and foreign companies across a broad range of industries on their local and international transactions involving M&A, joint ventures and corporate governance. He regularly advises private equity firms in fund formation and transactional work. Additionally, he has an active Finance Law practice, advising clients in domestic and cross-border corporate finance transactions, including syndicated lending and secured financing, with a deep regulatory and transactional knowledge of the non-banking sector. Gustavo’s Real Estate practice covers a wide variety of transactions and participants in this industry, highlighting his wide experience in hospitality and industrial projects. Gustavo serves as external counsel to a number of family offices and participates in several Board of Directors and Committees."],
        "qualifications": {
            "title": "Qualifications",
            "items": [
                {
                    "title": "Education and Professional Experience",
                    "p": ["Law Degree – Escuela Libre de Derecho (2001) ",
                        "LL.M Degree – Northwestern Pritzker School of Law (2002)",
                        "Foreign Associate – Morrison & Foerster, San Francisco (2002-2003)"
                    ]
                },
                {
                    "title": "Publications",
                    "p": ["• “México” Chapter in “Corporate Governance 2022”, published by ICLG, edited by Adam O. Emmerich and Sebastian V. Niles, Wachtell, Lipton, Rosen & Katz, 2022.",
                        "• “Mexico: An Introduction to Corporate/M&A”, published in Chambers and Partners Global Guide, 2022.",
                        "• “México” Chapter in “Corporate Governance 2020”, published by ICLG, edited by Adam O. Emmerich and Sebastian V. Niles, Wachtell, Lipton, Rosen & Katz, 2020.",
                        "• “Relevant Issues Involving M&A of Mid-Size Mexican Companies”, published by Latin American Law & Business Report, Thomson Reuters, August 2012",
                        "• “Update to the Proposed Amendments to the Mexican Competition Law”, published by Latin American Law & Business Report, Thomson Reuters, August 2010",
                        "• “Structuring Real Estate Projects in Mexico, Recent Experience”, published by North American Free Trade & Investment Report, WorldTrade Executive, March 2009",
                        "• “SAPI’s as an Efficient Vehicle for Private Equity Investments in Mexico”, published by Latin American Law & Business Report, WorldTrade Executive, April 2007",
                        "“New Amendments to Mexican Finances Serve to Deregulate Various Activities”, published by Latin American Law & Business Report, WorldTrade Executive, August 2006"
                    ]
                }
            ]
        },
        "affiliations": {
            "title": "Memberships & Affiliations ",
            "p": ["• American Bar Association"]
        },
        "languages": {
            "title": "Languages",
            "p": ["• Spanish", "• English"]
        }
    },
    "/partners/5": {
        "link": "/partners/5",
        "image": require('../../assets/equipo/Socios/Biografia/CesarMorales_Esc.jpg'),
        "image_team": require('../../assets/equipo/Socios/Home/CesarMorales_Esc.jpg'),
        "vcard": require('../../assets/vcards/César_Morales G..vcf'),
        "name": "César Morales G.",
        "subtitle": "Partner",
        "description": [
            { name: "Corporate and Commercial", link: "/practice-areas#corporate-and-commercial-law" },
            { name: "Mergers and Acquisitions", link: "" },
            { name: "Real Estate", link: "/practice-areas#real-estate" },
            { name: "Finance", link: "/practice-areas#finance" }
        ],
        "contact": {
            "title": "Contact",
            "location": "Mexico City",
            "phone": "+52 (55) 1167-3038",
            "email": "cesar.morales@creelabogados.com"
        },
        "p": ["César is a Partner of our Firm. He specializes in Corporate Law, including M&A, Real Estate and Finance, as well as in matters related to Gaming Law and Sports Law. César has over 20 years of experience and throughout his active professional career has advised domestic and foreign clients in establishing their operations in Mexico, as well as in a wide variety of joint ventures, mergers and acquisitions and corporate restructurings, in fund formation and in real estate projects, as well as in all types of financing transactions."],
        "qualifications": {
            "title": "Qualifications",
            "items": [
                {
                    "title": "Education and Professional Experience",
                    "p": ["Law Degree – Escuela Libre de Derecho (2004)",
                        "LL.M. Degree – The London School of Economics and Political Science (2006)",
                        "Foreign Associate – Morrison & Foerster, New York (2007-2008)"]
                }
            ]
        },
        "languages": {
            "title": "Languages",
            "p": ["• Spanish", "• English"]
        }
    },
    "/partners/7": {
        "link": "/partners/7",
        "image": require('../../assets/equipo/Socios/Biografia/PabloArellano_Esc.jpg'),
        "image_team": require('../../assets/equipo/Socios/Home/PabloArellano_Esc.jpg'),
        "vcard": require('../../assets/vcards/Pablo_Arellano Q..vcf'),
        "name": "Pablo Arellano Q.",
        "subtitle": "Partner",
        "description": [
            { name: "Corporate and Commercial", link: "/practice-areas#corporate-and-commercial-law" },
            { name: "Mergers and Acquisitions", link: "" },
            { name: "Finance", link: "/practice-areas#finance" },
            { name: "Real Estate", link: "/practice-areas#real-estate" },
            { name: "ESG", link: "/practice-areas#esg" },
            { name: "Compliance", link: "/practice-areas#compliance" }
        ],
        "contact": {
            "title": "Contact",
            "location": "Mexico City",
            "phone": "+52 (55) 1167-3040",
            "email": "pablo.arellano@creelabogados.com"
        },
        "p": ["Pablo is a Partner of our Firm. He specializes in Corporate Law, including M&A, as well as in Finance, and Real Estate. Pablo has ample experience on M&A transactions and corporate restructurings, as well as on the design, drafting and implementation of finance transactions, including domestic and cross-border credits, and security packages. Likewise, as part of his Real Estate practice, Pablo regularly advises foreign and domestic clients in the structuring and negotiation of real estate trusts, purchase and sale, and lease agreements."],
        "qualifications": {
            "title": "Qualifications",
            "items": [
                {
                    "title": "Education",
                    "p": [
                        "Law Degree – Universidad Iberoamericana (2010)",
                        "LL.M. Degree – University of California at Berkeley (2015)"]
                }
            ]
        },
        "languages": {
            "title": "Languages",
            "p": ["• Spanish", "• English"]
        }
    },
    "/partners/8": {
        "link": "/partners/8",
        "image": require('../../assets/equipo/Socios/Biografia/ErnestoSaldate_Esc.jpg'),
        "image_team": require('../../assets/equipo/Socios/Home/ErnestoSaldate_Esc.jpg'),
        "vcard": require('../../assets/vcards/Ernesto_Saldate A..vcf'),
        "name": "Ernesto Saldate del Alto",
        "subtitle": "Partner",
        "description": [{ name: "Litigation and Dispute Resolution", link: "/practice-areas#litigation-and-dispute-resolution" },
        { name: "Compliance", link: "/practice-areas#compliance" }],
        "contact": {
            "title": "Contact information",
            "location": "Mexico City",
            "phone": "+52 (55) 1167-3028",
            "email": "ernesto.saldate@creelabogados.com"
        },
        "p": ["Ernesto is a Partner of our Firm. He specializes in Civil and Commercial Litigation, and Arbitration. His practice encompasses advising and representing national and foreign companies in all types of complex litigation before local and federal courts, including constitutional, administrative, civil, and commercial litigation. In addition, Ernesto represents clients in alternative dispute resolution (ADR) mechanisms such as mediation and arbitration, participating in <span style='font-family: Poppins Italic'>ad hoc</span> procedures and before the main administrative institutions, both national and foreign. In coordination with our Corporate Practice, Ernesto represents clients in the design and implementation of prevention, management and remedial programs for regulatory compliance, as well as in related audit and investigation procedures."],
        "qualifications": {
            "title": "Qualifications",
            "items": [
                {
                    "title": "Education",
                    "p": ["Law Degree - Universidad Panamericana (2001)",
                        "Postgraduate Degree in Corporate and Commercial Law – Escuela Libre de Derecho 2007",
                        "Postgraduate Degree in Criminal Law – Escuela Libre de Derecho (2008)",
                        "Postgraduate Degree in Civil and Commercial Procedural Law – Universidad Panamericana (2009)",
                        "Postgraduate Degree in Civil and Commercial Law – Universidad Panamericana (2010)"
                    ]
                },
                {
                    "title": "Publications",
                    "p": ["• “Shareholder claims against M&A transactions in Mexico”, published by Lexology: Getting the Deal Through, April 2022.",
                        "•  “M&A Litigation in Mexico”, publicado por Lexology: Getting the Deal Through, Abril 2021."
                    ]
                }
            ]
        },
        "languages": {
            "title": "Languages",
            "p": ["• Spanish", "• English"]
        }
    },
    "/advisers/1": {
        "link": "/advisers/1",
        "image": require('../../assets/equipo/Consejeros/Biografia/LuisCreel_Esc.jpg'),
        "image_team": require('../../assets/equipo/Consejeros/Home/LuisJCreel_Esc.jpg'),
        "vcard": require('../../assets/vcards/Luis_J. Creel C..vcf'),
        "name": "Luis J. Creel Carrera",
        "subtitle": "Honorary Counsel",
        "description": [""],
        "contact": {
            "title": "Contact information",
            "location": "Mexico City",
            "phone": "+52 (55) 1167-3000",
            "email": "luis.creel@creelabogados.com"
        },
        "p": ["Luis is Honorary Counsel and founder of our Firm, together with Luis J. Creel Luján. During his long and active career Luis focused his practice on Corporate Law, having represented some of the largest Mexican industrial and financial groups, and leading foreign corporations on their strategic transactions and projects within Mexico. He was founder, chairman and member of the board of directors of several companies, both private and listed in the Mexican Stock Exchange, including IXE Grupo Financiero (today, part of Grupo Financiero Banorte). Additionally, Luis actively participated in the creation, development and consolidation of philanthropic institutions such as Fundación Casa Alianza México (Mexican affiliate of Covenant House), where he acted as Chairman of the Board of Trustees for two decades, as well as of Fomento de Investigación y Cultura Superior and Patronato Económico y de Desarrollo de la Universidad Panamericana."],
        "qualifications": {
            "title": "Qualifications",
            "items": [{
                "title": "Education and Professional Experience",
                "p": [
                    "• Law Degree - Escuela Libre de Derecho (1966)",
                    "• Introductory Program to the American Legal System – Princeton University (1966)",
                    "• LL.M. - Harvard University (1967)",
                    "• Postgraduate Degree in Finance and Business Administration – Universidad de las Américas (1971-1972)",
                    "• Executive Management Program AD-2 – IPADE (1972-1973)",
                    "• Diploma in Tax Law - Universidad Panamericana (1979)",
                ]
            }
            ]
        },
        "affiliations": {
            "title": "Memberships & Affiliations",
            "p": ["• Mexican Bar Association", "• Ilustre y Nacional Colegio de Abogados"]
        },
        "languages": {
            "title": "Languages",
            "p": ["• Spanish", "• English"]
        }
    },
    "/advisers/2": {
        "link": "/advisers/2",
        "image": require('../../assets/equipo/Consejeros/Biografia/JorgeTorres_esc.jpg'),
        "image_team": require('../../assets/equipo/Consejeros/Home/JorgeTorresB_Esc.jpg'),
        "vcard": require('../../assets/vcards/Jorge_Torres B..vcf'),
        "name": "Jorge Torres B.",
        "subtitle": "Senior Counsel",
        "description": [
            { name: "Real Estate", link: "/practice-areas#real-estate" },
            { name: "Corporate and Commercial", link: "/practice-areas#corporate-and-commercial-law" },
        ],
        "contact": {
            "title": "Contact information",
            "location": "Mexico City",
            "phone": "+52 (55) 1167-3010 ",
            "email": "jorge.torres@creelabogados.com"
        },
        "p": ["Jorge is Senior Counsel of our Firm. During his long-standing career, Jorge has developed a unique Real Estate practice. He has participated in some of the most relevant real estate matters and projects in Mexico, actively representing important Mexican and international groups in the acquisition, lease and development of real estate properties for hospitality, retail, industrial and residential projects throughout Mexico. Likewise, Jorge has ample experience leading numerous corporate and commercial transactions, including M&A, representing clients in several industry sectors."],
        "qualifications": {
            "title": "Qualifications",
            "items": [
                {
                    "title": "Education and Professional Experience",
                    "p": ["• Law Degree - Escuela Libre de Derecho (1985)",
                        "• Postgraduate Degree in International Commercial Arbitration – Escuela Libre de Derecho (1995)",
                        "• Foreign Associate - Gardere & Wynne – Dallas (1988)"
                    ]
                },
                {
                    "title": "Publications",
                    "p": ["• “Mexico” in “PLC Cross-border Corporate Real Estate Handbook”, published by Practical Law Company, United Kingdom, First to Ninth Editions (2004 to 2013).",
                        "• “Mexico” in “The Real Estate Law Review”, published by Law Business Research, 2012.",
                        "• “Derecho Inmobiliario, Temas Selectos y Perspectivas del Sector”, published by Editorial Porrúa and Instituto Tecnológico de México, 2010.",
                        "• “Overview of Mexican Real Estate Investments”, PLC Cross-border Corporate Real Estate Handbook, published by Practical Law Company, Fourth Edition 2009.",
                        "• “Real Estate Investments in Mexico”, PLC Cross-border Corporate Real Estate Handbook, published by Practical Law Company, Third Edition 2008."
                    ]
                }
            ]
        },
        "languages": {
            "title": "Languages",
            "p": ["• Spanish", "• English"]
        }
    },
    "/advisers/3": {
        "link": "/advisers/3",
        "image": require('../../assets/equipo/Consejeros/Biografia/AlejandroCreel_Esc.jpg'),
        "image_team": require('../../assets/equipo/Consejeros/Home/Alejandro_Esc.jpg'),
        "vcard": require('../../assets/vcards/Alejandro_Creel Y..vcf'),
        "name": "Alejandro Creel Y.",
        "subtitle": "Counsel",
        "description": [
            { name: "Corporate and Commercial", link: "/practice-areas#corporate-and-commercial-law" },
            { name: "Mergers and Acquisitions", link: "" },
            { name: "Real Estate", link: "/practice-areas#real-estate" },
            { name: "Natural Resources", link: "/practice-areas#natural-resources" },
        ],
        "contact": {
            "title": "Contact information",
            "location": "Mexico City",
            "phone": "+52 55-1167-3041, ext. 160",
            "email": "alejandro.creel@creelabogados.com"
        },
        "p": ["Alejandro is Counsel of our Firm. He specializes in Corporate Law and Real Estate. Alejandro focuses his Corporate practice in advising national and international corporations and funds in M&A transactions, corporate restructurings, joint ventures and corporate governance. On the Real Estate front, Alejandro actively participates in complex real estate transactions and projects, representing investors, developers and operators in the structuring of trust, purchase and sale and lease agreements, as well as in the formation of condominium property, time-sharing and fractional ownership regimes."],
        "qualifications": {
            "title": "Qualifications",
            "items": [
                {
                    "title": "Education",
                    "p": ["• Law Degree – Universidad Iberoamericana (2006)",
                        "• LL.M. Degree - Instituto de Empresa (IE), Madrid, Spain (2009)"
                    ]
                }
            ]
        },
        "languages": {
            "title": "Languages",
            "p": ["• Spanish", "• English"]
        }
    },
    "/advisers/4": {
        "link": "/advisers/4",
        "image": require('../../assets/equipo/Consejeros/Biografia/AlejandroGorozpe_Bio.jpg'),
        "image_team": require('../../assets/equipo/Consejeros/Home/AlejandroGorozpe_Home.jpg'),
        "vcard": "",
        "name": "Alejandro Gorozpe Z.",
        "subtitle": "Counsel",
        "description": [
            { name: "Labor and Employment", link: "/practice-areas#labor-and-employment" }
        ],
        "contact": {
            "title": "Contact information",
            "location": "Mexico City",
            "phone": "+52 (55) 1167-3000",
            "email": "alejandro.gorozpe@creelabogados.com"
        },
        "p": ["Alejandro is Counsel of our Firm. Alejandro specializes in Labor and Employment. He has over 15 years of experience actively representing companies in connection with labor and social security related matters, including the hiring and termination of employees, transfer of employees derived from asset-based acquisitions and mergers, and labor implications with respect to M&A transactions, as well as in drafting and negotiating collective bargaining agreements. Alejandro also focuses his practice on litigation before Labor Courts."],
        "qualifications": {
            "title": "Qualifications",
            "items": [
                {
                    "title": "Education",
                    "p": ["• Law Degree – Universidad Anahuac (2007)",
                        "• Postgraduate Degree in the American Legal System – Boston University (2008)",
                        "• Diploma in Negotiation – Harvard University (2013)"
                    ]
                }
            ]
        },
        "languages": {
            "title": "Languages",
            "p": ["• Spanish", "• English"]
        }
    },
    "/partners/9": {
        "link": "/partners/9",
        "image": require('../../assets/equipo/Socios/Biografia/Guillermo_Bio.jpg'),
        "image_team": require('../../assets/equipo/Socios/Home/Guillermo_Home.jpg'),
        "vcard": "",
        "name": "Guillermo Rocha L.",
        "subtitle": "Partner",
        "description": [
            { name: "Corporate and Commercial", link: "/practice-areas#corporate-and-commercial-law" },
            { name: "Mergers and Acquisitions", link: "" },
            { name: "Finance", link: "/practice-areas#finance" },
            { name: "Compliance", link: "/practice-areas#compliance" }
        ],
        "contact": {
            "title": "Contact information",
            "location": "Mexico City",
            "phone": "+52 (55) 1167-3039",
            "email": "guillermo.rocha@creelabogados.com"
        },
        "p": ["Guillermo is a Partner of our Firm. Guillermo specializes in Corporate Law and Finance, with a focus on structured and asset-backed finance and private equity. He has over 25 years of experience participating in transactions as legal advisor, structurer and principal. Guillermo has been Head of Structuring and General Counsel of several private debt funds, and CEO & Founder of non-bank financial institutions. Guillermo participates in several Board of Directors and Committees."],
        "qualifications": {
            "title": "Qualifications",
            "items": [
                {
                    "title": "Educación",
                    "p": ["Law Degree – Instituto Tecnológico Autónomo de México (2002)",
                        "LL.M Degree – Northwestern University School of Law, with honors (2006)",
                        "Graduate Certificate in Business Administration – Kellogg School of Management (2006)",
                        "Foreign Associate – Gibson, Dunn & Crutcher, New York (2007-2008)"
                    ]
                }
            ]
        },
        "languages": {
            "title": "Languages",
            "p": ["• Spanish", "• English"]
        }
    },
    "/associates/1": {
        "link": "/associates/1",
        "image": require('../../assets/equipo/Asociados/Biografía/DiegoCorvera_Bio.jpg'),
        "image_team": require('../../assets/equipo/Asociados/Home/DiegoCorvera_Home.jpg'),
        "vcard": "",
        "name": "Diego Corvera H.",
        "subtitle": "Associate",
        "description": [
            { name: "Corporate and Commercial", link: "/practice-areas#corporate-and-commercial-law" },
            { name: "Mergers and Acquisitions", link: "" },
            { name: "Finance", link: "/practice-areas#finance" }
        ],
        "contact": {
            "title": "Contact information",
            "location": "Mexico City",
            "phone": "+52 (55) 1167-3000, ext. 127",
            "email": "diego.corvera@creelabogados.com"
        },
        "p": ["Diego specializes in Corporate and Finance. He focuses his practice in local and cross-border M&A transactions, corporate restructurings, and general corporate and commercial matters. He also focuses his practice on all types of financial transactions, including corporate finance, and capital and debt markets deals. Likewise, Diego provides advice on Insurance Law related matters, including contract review and consultations on regulatory aspects of insurance institutions."],
        "qualifications": {
            "title": "Qualifications",
            "items": [
                {
                    "title": "Education",
                    "p": ["• Law Degree – Universidad Anahuac (2022)", "• MS Degree in International Business – Hult International Business School, London, UK (2024)", "• Diploma in Arbitration - The International Academy for Arbitration Law. Paris, France (2024)" ]
                }
            ]
        },
        "languages": {
            "title": "Languages",
            "p": ["• Spanish", "• English"]
        }
    },
    "/associates/4": {
        "link": "/associates/4",
        "image": require('../../assets/equipo/Asociados/Biografía/Tsen_Esc.jpg'),
        "image_team": require('../../assets/equipo/Asociados/Home/Tsen_Esc.jpg'),
        "vcard": require('../../assets/vcards/Tsen-Fang Lin_.vcf'),
        "name": "Tsen Fang-Lin",
        "subtitle": "Associate",
        "description": [
            { name: "Corporate and Commercial", link: "/practice-areas#corporate-and-commercial-law" },
            { name: "Mergers and Acquisitions", link: "" },
            { name: "Finance", link: "/practice-areas#finance" }
        ],
        "contact": {
            "title": "Contact information",
            "location": "Mexico City",
            "phone": "+52 (55) 1167-3000 ext. 159",
            "email": " tsen-fang.lin@creelabogados.com"
        },
        "p": ["Lin specializes in Corporate and Financial Law. She advises Mexican and international clients in corporate and commercial matters, including the daily maintenance of their corporations in Mexico, as well as the drafting and negotiation of a wide variety of contracts and counseling in M&A transactions. Her practice involves foreign investment matters, as well as data protection and anti-money laundering regulations. Likewise, Lin provides advice on finance transactions, including domestic and cross-border credits and the implementation of their corresponding security packages."],
        "qualifications": {
            "title": "Qualifications",
            "items": [
                {
                    "title": "Education",
                    "p": ["• Law Degree – Escuela Libre de Derecho (2019)"]
                }
            ]
        },
        "languages": {
            "title": "Languages",
            "p": ["• Spanish", "• English", "• Mandarin", "• Minnan Taiwanese"]
        }
    },
    "/associates/6": {
        "link": "/associates/6",
        "image": require('../../assets/equipo/Asociados/Biografía/SandraLopez_Esc.jpg'),
        "image_team": require('../../assets/equipo/Asociados/Home/SandraLopez_Esc.jpg'),
        "vcard": require('../../assets/vcards/Sandra_López CH..vcf'),
        "name": "Sandra López Ch.",
        "subtitle": "Associate",
        "description": [{ name: "Corporate and Commercial", link: "/practice-areas#corporate-and-commercial-law" },
        { name: "Mergers and Acquisitions", link: "" },
        { name: "Finance", link: "/practice-areas#finance" },
        { name: "Regulatory Compliance", link: "/practice-areas#compliance" },
        ],
        "contact": {
            "title": "Contact information",
            "location": "Mexico City",
            "phone": "+52 (55) 1167-3022",
            "email": "sandra.lopez@creelabogados.com"
        },
        "p": ["Sandra specializes in Corporate Law. She advises Mexican and foreign clients in corporate and commercial matters, including the daily maintenance of their corporations, and in the preparation and negotiation of a vast variety of contracts relating to their regular business. Her practice involves providing advice on consultations relating to compliance of foreign investment, data protection, anti-money laundering and anti-corruption regulations. Likewise, Sandra has actively participated in a wide variety of financing transactions, both domestic and cross-border, as well as in advising clients in financial regulation consultations."],
        "qualifications": {
            "title": "Qualifications",
            "items": [
                {
                    "title": "Education",
                    "p": ["• Law Degree - Universidad Iberoamericana (2017)",
                        "• Postgraduate Degree and LL.M. Degree in Private Law – Universidad Anáhuac (2017-2019)"
                    ]
                }
            ]
        },
        "languages": {
            "title": "Languages",
            "p": ["• Spanish", "• English"]
        }
    },
    "/associates/8": {
        "link": "/associates/8",
        "image": require('../../assets/equipo/Asociados/Biografía/FernandaMelgar_Esc.jpg'),
        "image_team": require('../../assets/equipo/Asociados/Home/MariaFernandaMelgar_Esc.jpg'),
        "vcard": require('../../assets/vcards/María_Fernanda Melgar G..vcf'),
        "name": "Fernanda Melgar G.",
        "subtitle": "Associate",
        "description": [
            { name: "Corporate and Commercial", link: "/practice-areas#corporate-and-commercial-law" },
            { name: "Mergers and Acquisitions", link: "" },
            { name: "Finance", link: "/practice-areas#finance" },
            { name: "Antitrust and Competition", link: "/practice-areas#antitrust-and-competition" }],
        "contact": {
            "title": "Contact information",
            "location": "Mexico City",
            "phone": "+52 (55) 1167-3010, ext.131",
            "email": "fernanda.melgar@creelabogados.com"
        },
        "p": ["Fernanda specializes in Corporate and Finance. She focuses her practice in M&A transactions, corporate restructurings, general corporate and commercial matters, and the daily maintenance of corporations, including foreign investment and regulatory compliance, as well as in coordinating legal due diligences. Likewise, Fernanda provides advice on regulatory matters involving banking (FinTech) and participates in the structuring of loan and security agreements. Fernanda provides advice in Antitrust matters, including the representation of clients in notification procedures before the Federal Economic Competition Commission."],
        "qualifications": {
            "title": "Qualifications",
            "items": [
                {
                    "title": "Education",
                    "p": ["• Law Degree – Universidad Iberoamericana (2020)"]
                }
            ]
        },
        "languages": {
            "title": "Languages",
            "p": ["• Spanish", "• English"]
        }
    },
    "/associates/9": {
        "link": "/associates/9",
        "image": require('../../assets/equipo/Asociados/Biografía/MoisesProchovnik_Esc.jpg'),
        "image_team": require('../../assets/equipo/Asociados/Home/Moises_Esc.jpg'),
        "vcard": require('../../assets/vcards/Moisés_Prochovnik A..vcf'),
        "name": "Moisés Prochovnik A. ",
        "subtitle": "Associate",
        "description": [
            { name: "Corporate and Commercial", link: "/practice-areas#corporate-and-commercial-law" },
            { name: "Finance", link: "/practice-areas#finance" },
            { name: "Mergers and Acquisitions", link: "" },
        ],
        "contact": {
            "title": "Contact information",
            "location": "Mexico City",
            "phone": "+52 (55) 1167-3010, ext. 122",
            "email": "moises.prochovnik@creelabogados.com"
        },
        "p": ["Moises specializes in Corporate and Finance. He focuses his practice in M&A transactions, corporate restructurings, issuance of securities in capital markets, structuring and negotiation of loan agreements and advice to all types of financial entities. Likewise, Moises provides advice on the structuring of trusts and stock purchase agreements. He has extensive experience in advising companies and financial entities in securities transactions, including private and public offerings, and on regulatory matters before the Mexican Stock Exchanges and the National Banking and Securities Commission."],
        "qualifications": {
            "title": "Qualifications",
            "items": [
                {
                    "title": "Education",
                    "p": ["• Law Degree – Escuela Libre de Derecho (2017)",
                        "• Postgraduate Degree in Insurance and Bonds – Escuela Libre de Derecho (2015)",
                        "• LL.M. Degree – Columbia University (2022)",
                        "• Currently, collaborating as Foreign Associate at Winston & Strawn LLP, New York"
                    ]
                },
                {
                    "title": "Academic Experience",
                    "p": ["Moises has been Assistant Professor of Securities Law at Escuela Libre de Derecho."]
                }
            ]
        },
        "languages": {
            "title": "Languages",
            "p": ["• Spanish", "• English", "• Hebrew"]
        }
    },
    "/associates/10": {
        "link": "/associates/10",
        "image": require('../../assets/equipo/Asociados/Biografía/JonathanSegura_Esc.jpg'),
        "image_team": require('../../assets/equipo/Asociados/Home/JonathanSegura_Esc.jpg'),
        "vcard": require('../../assets/vcards/Jonathan_Segura M..vcf'),
        "name": "Jonathan Segura M.",
        "subtitle": "Associate",
        "description": [{ name: "Litigation and Dispute Resolution", link: "/practice-areas#litigation-and-dispute-resolution" }],
        "contact": {
            "title": "Contact information",
            "location": "Mexico City",
            "phone": "+52 (55) 1167-3010, ext. 118",
            "email": "jonathan.segura@creelabogados.com"
        },
        "p": ["Jonathan specializes in Civil and Commercial Litigation. He concentrates his practice in all types of civil, commercial, administrative and constitutional proceedings and litigation, as well as in mediation, conciliation and other alternative means of dispute resolution. He has experience in ordinary and special proceedings in the different judicial and administrative instances. Likewise, Jonathan provides advice on the preparation of claims, offering and evaluation of evidence, and appeals, as well as in the drafting of civil and commercial contracts, including purchases and sale, leases, mortgages and trusts, and recognition and payment of debts."],
        "qualifications": {
            "title": "Qualifications",
            "items": [
                {
                    "title": "Education",
                    "p": ["• Law Degree – Universidad Nacional Autónoma de México (2021)"
                    ]
                }
            ]
        },
        "languages": {
            "title": "Languages",
            "p": ["• Spanish", "• English"]
        }
    },
    "/associates/11": {
        "link": "/associates/11",
        "image": require('../../assets/equipo/Asociados/Biografía/PabloTorres_Esc.jpg'),
        "image_team": require('../../assets/equipo/Asociados/Home/PabloTorres_Esc.jpg'),
        "vcard": require('../../assets/vcards/Pablo_Torres A..vcf'),
        "name": "Pablo Torres A.",
        "subtitle": "Associate",
        "description": [
            { name: "Corporate and Commercial", link: "/practice-areas#corporate-and-commercial-law" },
            { name: "Mergers and Acquisitions", link: "" },
            { name: "Regulatory Compliance", link: "/practice-areas#compliance" },
        ],
        "contact": {
            "title": "Contact information",
            "location": "Mexico City",
            "phone": "+52 (55) 1167-3021",
            "email": "pablo.torres@creelabogados.com"
        },
        "p": ["Pablo specializes in Corporate and Commercial Law. He focuses his practice in general corporate and commercial matters, including corporate restructurings, joint ventures and corporate governance, and also assists our clients in compliance with foreign investment, data protection and anti-money laundering regulations. Pablo’s practice involves transactional corporate work, particularly in M&A transactions. Likewise, he has experience in Intellectual Property matters, including the registration and maintenance of IP rights, as well as in IP due diligences."],
        "qualifications": {
            "title": "Qualifications",
            "items": [
                {
                    "title": "Education",
                    "p": ["• Law Degree – Instituto Tecnológico y de Estudios Superiores de Occidente (ITESO) (2021)",
                        "• Postgraduate Degree in Corporate Law - Instituto Tecnológico Autónomo de México (2022)",
                        "• LL.M. Degree – University of Aberdeen, U.K. (2024)"
                    ]
                }
            ]
        },
        "languages": {
            "title": "Languages",
            "p": ["• Spanish", "• English", "• German"]
        }
    },
}

export default en;